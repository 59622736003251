import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import SideDrawer from './comp/SideDrawer';
import Supportsidenav from '../supportsidenav';
import "../supportnav.css";


export default function Roles(props) {


    let data1 = localStorage.getItem("userdetail");
    data1 = JSON.parse(data1);
    const [userData, setUserData] = useState(data1);

    const [rolesList,setRolesList]=useState([])
    const [isLoading,setIsLoading]=useState(true)

    const [filteredList,setFilteredList]=useState([])
    const [search,setSearch]=useState('')

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentNumber, setCurrentNumber] = useState(null);
    const handleOpenMenu = (event, number) => {
      setAnchorEl(event.currentTarget);
      setCurrentNumber(number);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setCurrentNumber(null);
    }

    const getRolesList = async()=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-role-with-user/`,{
                method:`GET`,
                headers: {
                    'Accept': "application/json",
                    "Content-Type": "application/json",
                    'Authorization': "Token " + userData.token,
                  }
            })
            const data = await response.json()
            console.log(data,'Roles')
            setRolesList(data.payload.roles)
            setIsLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const filterList = (txt) => {
      let filteredName = rolesList.filter(el =>el.role.toLowerCase().indexOf(txt.toLowerCase()) !== -1)
      // let filteredNumber = userList.filter(el =>el.mobile_number.toLowerCase().indexOf(txt.toLowerCase()) !== -1)
      // console.log(filteredName,'filtered')
      setFilteredList(filteredName)
      setSearch(txt)
    }

    useEffect(()=>{
        getRolesList()
    },[])

    const container = {
        display:'flex',
        width:'100%'
    }
    const currentPageContainer = {
        backgroundColor:'#E5E5E5',
        minHeight:'100vh',
        padding:20,
        width:'100%'
    }
    const headerBarStyle = {
        backgroundColor:'white',
        borderRadius:16,
        padding:20,
        justifyContent:'center',
        fontWeight:'600',
        fontSize:35,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    }
    const headerRowStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:8,
        paddingLeft:25,
        justifyContent:'space-between',
        marginTop:8,
        marginBottom:10,
        flexDirection:'row',
        display:'flex',
      }
      const headerItemStyle = {
        width:'35%',
        justifyContent:'start',
        color:'#858585'
      }
      const rowStyle = {
        backgroundColor:'white',
        borderRadius:8,
        padding:8,
        paddingLeft:25,
        justifyContent:'start',
        alignItems:'center',
        marginTop:8,
        flexDirection:'row',
        display:'flex'
      }
      const rowItemStyle = {
        width:'35%',
        justifyContent:'start',
        color:'#858585',
        fontWeight:'400',
        fontSize:'16px'
      }
      const subRowItemStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:25,
        marginRight:10,
        paddingBlock:6,
        paddingInline:10,
        marginBottom:4,
        fontWeight:'400',
    fontSize:'16px'
      }
      const statusAvailable = {
        backgroundColor:'#DDFFC2',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#53BC00'
      }
      const statusUnavailable = {
        backgroundColor:'#FFE8E8',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#BC0000'
      }
      const buttonStyle = {
        backgroundColor:'#012C63',
        fontSize:18,
        borderRadius:8,
        paddingBlock:10,
        paddingInline:16.5,
        width:143,
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        display:'flex'
      }


    

  return (
    <div className='row support_row' >
    <div className='col-lg-2 col-md-2 support_col' >
<Supportsidenav/>
    </div>
    <div className='col-lg-10 col-md-10 support_col' >
    <div style={currentPageContainer} >

      <div style={headerBarStyle} >
              Roles    
              <button style={buttonStyle} 
              onClick={()=>props.history.push({
                pathname: "/support/CreatNewRole",
                state:{
                  role_data:
            null
                }
              })
              } >Create New</button>
      </div>

      <div style={{width:'100%',justifyContent:'space-between',display:'flex',flexDirection:'row',}} >

          <div style={{justifyContent:'start',display:'flex',flexDirection:'row',flex:1,padding:15}} >
          <TextField
          style={{backgroundColor:'white'}}
            id="outlined-with-icon"
            label="Search"
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        // value={search}
        onChange={(e)=>filterList(e.target.value)}
        variant="outlined"
      />
   
          </div>

      </div>


      <div style={headerRowStyle} >
      <h4 style={headerItemStyle} >Roles Names</h4>
      <h4 style={{...headerItemStyle,width:'65%'}} >Assigned To</h4>
      {/* <h4 style={{...headerItemStyle,justifyContent:'flex-end',alignItems:'flex-end'}} >Status</h4> */}
  
    </div>
  {!isLoading?
  search.length>0?
  filteredList.map((item,index)=>{
    return(
      <div key={index} style={rowStyle} 
      // onClick={()=>props.history.push({
      //   pathname: "/support/CreatNewRole",
      //   state:{
      //     role_data:
      //     // null
      //     item
      //   }
      // })
      // }
      >
        <h5 style={rowItemStyle} >{item.role}</h5>
        <h5 style={{...rowItemStyle,width:'60%'}} >
    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >       
          {item.users.map((itm,i)=>{
          return(
            <span key={i} style={subRowItemStyle} >
              {itm.first_name}
            </span>
          )
        })}
        </div>
        </h5>
        <div >
        <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={currentNumber === index ? 'long-menu' : undefined}
      aria-expanded={currentNumber === index ? 'true' : undefined}
      aria-haspopup="true"
      onClick={(e) => handleOpenMenu(e, index)}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={currentNumber === index}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem 
        onClick={()=>props.history.push({
        pathname: "/support/EditRolePermissons",
        state:{
          role_data:
          // null
          item
        }
      })
      }
      >Edit Role</MenuItem>
      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
      <MenuItem onClick={handleClose}>Logout</MenuItem> */}
    </Menu>
  </div>
      </div>
    )
  }):
    rolesList.map((item,index)=>{
      return(
        <div key={index} style={rowStyle} 
        // onClick={()=>props.history.push({
        //   pathname: "/support/CreatNewRole",
        //   state:{
        //     role_data:
        //     // null
        //     item
        //   }
        // })
        // }
        >
          <h5 style={rowItemStyle} >{item.role}</h5>
          <h5 style={{...rowItemStyle,width:'60%'}} >
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >       
            {item.users.map((itm,i)=>{
            return(
              <span key={i} style={subRowItemStyle} >
                {itm.first_name}
              </span>
            )
          })}
          </div>
          </h5>
          <div >
          <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={currentNumber === index ? 'long-menu' : undefined}
        aria-expanded={currentNumber === index ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleOpenMenu(e, index)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={currentNumber === index}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem 
          onClick={()=>props.history.push({
          pathname: "/support/EditRolePermissons",
          state:{
            role_data:
            // null
            item
          }
        })
        }
        >Edit Role</MenuItem>
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
        </div>
      )
    })
    :
    <div>
        ...Loading
    </div>
    }

      {/* <table >
  <tr style={headerRowStyle} >
    <th style={headerStyle} >Roles</th>
    <th style={headerStyle} >Assigned To</th>
    <th style={headerStyle} >Status</th>
      
  </tr>
  {rolesList.map((item,index)=>{
      return(
  <tr style={tableRowStyle} >
    <td style={tableDataStyle} >{item.role}</td>
    <td style={tableDataStyle} >{item.users.map((itm,i)=>{
        return(
            <span key={i} style={{backgroundColor:'#EDEDED',borderRadius:25,marginRight:10,padding:4,paddingInline:8}} >
                {itm.first_name}
            </span>
        )
        })}</td>
    <td style={{backgroundColor:'#DDFFC2',borderRadius:25}} >{item.status}</td>
  </tr>
      )
  })
  }
</table> */}

      </div>
      </div>
      </div>
  )
}
