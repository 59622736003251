import React,{useState,useEffect} from 'react'
import SideDrawer from './comp/SideDrawer';
import Multiselect from 'multiselect-react-dropdown';
import Swal from 'sweetalert2'
import { fontSize } from '@mui/system';
import Supportsidenav from '../supportsidenav';

export default function AddNewRole(props) {

    let data1 = localStorage.getItem("userdetail");
    data1 = JSON.parse(data1);
    const [userData, setUserData] = useState(data1);

    const [allPagesAndPageAction,setAllPagesAndPageAction]=useState([])
    const [schoolList,setSchoolList]=useState([])
    const [selectedCenterIdList,setSelectedCenterIdList]=useState([])
    const [pageActionList,setPageActionList]=useState([])
    const [name,setName]=useState('')
    const [mobileNumber,setMobileNumber]=useState('')
    const [emailId,setEmailId]=useState('')

    
    const clearData = () =>{
      setName('')
      setMobileNumber('')
      setEmailId('')
      setPageActionList([])
      setSelectedCenterIdList([])
    }


    const getAllPagesAndPageActions = async()=>{
        // console.log('tiken,',userData.token)
        try{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-page-action/`,{
                method:`GET`,
                headers: {
                    'Accept': "application/json",
                    "Content-Type": "multipart/form-data",
                    'Authorization': "Token " + userData.token,
                  },
                  // body:formdata
            })
            const data = await response.json()
            console.log(data,'all page')
            setAllPagesAndPageAction(data.payload.pages)

        }catch(err){
            console.log(err)
        }
    }

    const addRole = async()=>{
      // console.log('tiken,',userData.token)
      try{
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-edit-role/`,{
              method:`POST`,
              headers: {
                  'Accept': "application/json",
                  // "Content-Type": "multipart/form-data",
                  'Content-Type' : "application/json",
                  'Authorization': "Token " + userData.token,
                },
                body:JSON.stringify({"role_page_action":[{"role_id":"","role":name,"pages":pageActionList}]})
          })
          const data = await response.json()
          console.log(data,'create new role')
          if(!response.ok){
            Swal.fire({
              title: `${data.payload.Error}`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
            })
          }else{
            Swal.fire({
              title: `${data.message}`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then(()=>{
              props.history.push({
                pathname: "/support/Roles",
              })
            })
          }

      }catch(err){
          console.log(err)
          Swal.fire({
            title: `Something went wrong`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
          })
      }
  }


    // const handleOnChange = (actnObj)=>{
    //   setPageActionList([...pageActionList,{"role_id":roleObj.role_id}])
    //   console.log(pageActionList,'rolelist')
    // }

    const handleOnChange = (actnObj)=>{
      // console.log(actnObj,'roleobj')
      if(pageActionList.find((e)=>e.page_action_id==actnObj.page_action_id)){
        let filtered = pageActionList.filter((item)=>item.page_action_id!=actnObj.page_action_id)
        setPageActionList(filtered)
        console.log(filtered,'Page Action List')
      }else{
        setPageActionList([...pageActionList,{"page_action_id":actnObj.page_action_id}])
        console.log(pageActionList,'page Action List')
      }
    }

    useEffect(()=>{
      getAllPagesAndPageActions()
    },[])

    const container = {
        display:'flex',
        width:'100%'
    }
    const currentPageContainer = {
        backgroundColor:'#E5E5E5',
        minHeight:'100vh',
        padding:20,
        width:'100%'
    }
    const headerBarStyle = {
        backgroundColor:'white',
        borderRadius:16,
        padding:20,
        justifyContent:'center',
        fontWeight:'600',
        fontSize:35,
        marginBottom:20
    }
    const headerRowStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:8,
        paddingLeft:25,
        justifyContent:'start',
        marginBottom:10,
        flexDirection:'row',
        display:'flex',
      }
      const headerItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const rowStyle = {
        backgroundColor:'white',
        borderRadius:8,
        padding:8,
        paddingLeft:25,
        justifyContent:'start',
        marginTop:8,
        flexDirection:'row',
        display:'flex'
      }
      const rowItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const subRowItemStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:25,
        marginRight:10,
        paddingBlock:6,
        paddingInline:10
      }
      const statusAvailable = {
        backgroundColor:'#DDFFC2',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#53BC00'
      }
      const statusUnavailable = {
        backgroundColor:'#FFE8E8',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#BC0000'
      }
      const basicDetailsInnerContainer = {
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'flex-start'
      }
      const permissionsListContainer = {
        backgroundColor:'white',
        borderRadius:8,
        padding:20,
        marginBottom:20
      }
      const actionListContainer = {
        backgroundColor:'#F4F7FE',
        borderRadius:8,
        padding:10,
        marginBottom:20
      }
      const pageListContainer = {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginLeft:8,
        flexWrap:'wrap'
      }
      const page = {
        marginLeft:8
      }
      const checkbox = {
        marginRight:10
      }
      const buttonStyle = {
        backgroundColor:'#012C63',
        fontSize:18,
        borderRadius:8,
        paddingBlock:10,
        paddingInline:16.5,
        width:143,
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        display:'flex'
      }

  return (
    <div className='row support_row' >
        <div className='col-lg-2 col-md-2 support_col' >
    <Supportsidenav />
        </div>
        <div className='col-lg-10 col-md-10 support_col' >
        <div style={currentPageContainer} >
            <div style={headerBarStyle} >
              Create New Role
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Basic Details
             </h5>
            </div>

            <div style={permissionsListContainer} >
              <div style={basicDetailsInnerContainer} >
                
                <div style={{marginRight:20}} >
                  <p style={{fontSize:12}} >
                    Role Name<span style={{color:'red'}} >*</span>
                  </p>
                  <input placeholder='Add Role Name' type='text' onChange={(txt)=>setName(txt.target.value)} style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37}} />
                    
                </div>

              </div>
            </div>


            

           

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Add on Permissions
             </h5>
            </div>


            <div style={permissionsListContainer} >
              {allPagesAndPageAction.map((item,index)=>{
                return(
                  
              <div key={index} style={actionListContainer} >
                
                  {item.page}

                  <div style={pageListContainer} >
                  {item.actions.map((itm,i)=>{
                    return(
                      <div key={i} style={page} >
                         <input
          type="checkbox"
          style={checkbox}
          // checked={isChecked}
          onChange={()=>handleOnChange(itm)}
        />
                        {itm.action}
                        </div>
                    )
                  })}
                  </div>
              </div>
                )
              })
              }
             
            </div>

            <div style={headerBarStyle} >
            <button
            onClick={()=>{
              name==''?
              Swal.fire({
                title: `Input Field Empty`,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'Ok',
              })
              :
              addRole()
              // console.log('null')
            }}
              style={buttonStyle} >
                Create Role
              </button>
            </div>

        </div>

    </div>
    </div>
  )
}