import React,{useState,useEffect} from 'react'
import SideDrawer from './comp/SideDrawer';
import Swal from 'sweetalert2'
import { fontSize } from '@mui/system';
import Supportsidenav from '../supportsidenav';

export default function CreateNewRole(props) {

    let data1 = localStorage.getItem("userdetail");
    data1 = JSON.parse(data1);
    const [userData, setUserData] = useState(data1);

    const [allPagesAndActionsList,setAllPagesAndActionsList]=useState([])
    const [name,setName]=useState('')
    const [roleId,setRoleId]=useState()
    const [pagesActionList,setPagesActionList]=useState([])
    const [roleData,setRoleData]=useState([])
    // const [pageIdList,setPageIdList]=useState([])
    const [pageName,setPageName]=useState('')
    const [actionName,setActionName]=useState('')
    const [reload,setReload]=useState(false)

    
    

    const getAllPagesAndActionsList = async(role_id)=>{
        // console.log('tiken,',userData.token)
        try{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-page-action-for-role/?role_id=${role_id}`,{
                method:`GET`,
                headers: {
                    'Accept': "application/json",
                    "Content-Type": "application/json",
                    'Authorization': "Token " + userData.token,
                  },
                  // body:formdata
            })
            const data = await response.json()
            console.log(data.payload.pages,'peges and actions')
            setAllPagesAndActionsList(data.payload.pages)
            updatePageActionList(data.payload.pages)

        }catch(err){
            console.log(err)
            Swal.fire({
              title: `Something went wrong`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
              
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              // if (result.isConfirmed) {
              // } 
            })
        }
    }

    const updatePageActionList = (pages) => {
      let a = []
      pages.map((item,index)=>{
        return(
          item.actions.map((itm,i)=>{
              if(itm.is_enabled){
                a.push({"page_action_id":itm.page_action_id})
              }
          })
        )
      })
      setPagesActionList(a)
      // console.log(a,'current permission')
    }

    const addNewPageOrAction = async(page,action)=>{
      try{
        let formdata = new FormData();
        formdata.append('action_name',action)    
        formdata.append('page_name',page)

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-page-action/`,{
            method:`POST`,
            headers: {
                'Accept': "application/json",
                // "Content-Type": "application/json",
                'Authorization': "Token " + userData.token,
              },
              body:formdata
        })
        const data = await response.json()
        console.log(data,'addpage and action')
        // setAllPagesAndActionsList(data.payload.pages)
        if(response.ok){          
          Swal.fire(`${data.message}`)
          setReload(!reload)
        }
    }catch(err){
        console.log(err)
        Swal.fire({
          title: `Something went wrong`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          // if (result.isConfirmed) {
          // } 
        })
    }
    }

    const updateRole = async()=>{
      // console.log('tiken,',userData.token)
      try{
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-edit-role/`,{
              method:`POST`,
              headers: {
                  'Accept': "application/json",
                  // "Content-Type": "multipart/form-data",
                  'Content-Type' : "application/json",
                  'Authorization': "Token " + userData.token,
                },
                // body:JSON.stringify({'role_page_action':[{'role_id':`${roleData.role_id}`,'role':`${name}`,"pages":pagesActionList,}]})
                body:JSON.stringify({"role_page_action":[{"role_id":`${roleData.role_id}`,"role":`${name}`,"pages":pagesActionList}]})
          })
          const data = await response.json()
          console.log(data,'create new use')
          Swal.fire({
            title: `${data.message}`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            props.history.push({
              pathname: "/support/Roles",
            })
          })

      }catch(err){
          console.log(err)
          Swal.fire({
            title: `Something went wrong`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            
          })
      }
  }

  const handleOnChange = (pageObj)=>{
    // console.log(roleObj,'roleobj')
    if(pagesActionList.find((e)=>e.page_action_id==pageObj.page_action_id)){
      let filtered = pagesActionList.filter((item)=>item.page_action_id!=pageObj.page_action_id)
      setPagesActionList(filtered)
      console.log(filtered,'page action filtered')
    }else{
      setPagesActionList([...pagesActionList,{"page_action_id":pageObj.page_action_id}])
      console.log(pagesActionList,'page action selected list')
    }
  }

    
    useEffect(()=>{
      console.log(props.history.location.state.role_data,'roledata')
      if(props.history.location.state.role_data==null||props.history.location.state.role_data==undefined){
        Swal.fire({
          title: 'Enter New Role Name',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'on'
          },
          showCancelButton: false,
          confirmButtonText: 'Enter',
          showLoaderOnConfirm: true,
          preConfirm: async(name) => {
            try{
              console.log(name,'namee',userData.token)
              let formdata = new FormData()
              formdata.append('role_name',`${name}`)
              const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-role/`,{
                method:`POST`,
                headers: {
                    'Accept': "application/json",
                    // "Content-Type":'multipart/form-data',
                    // 'Content-Type' : "application/json",
                    'Authorization': "Token " + userData.token,
                  },
                  body:formdata
                  // body:JSON.stringify({'role_name':`${name}`})
            })
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  const data = await response.json()
                  console.log(data,'ddddd')
                  getAllPagesAndActionsList(data.payload.role_id)
                  setName(data.payload.role_name)
                  setRoleData(data.payload)
                }
                catch(error) {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  }
          },
          // allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          console.log(result,'result')
          if(!result.isConfirmed){
            setReload(!reload)
          }
            // console.log(reload)
        })
      }else{
          getAllPagesAndActionsList(props.history.location.state.role_data.role_id)
          setName(props.history.location.state.role_data.role)
          setRoleData(props.history.location.state.role_data)
      }

    },[reload])

    const container = {
        display:'flex',
        width:'100%'
    }
    const currentPageContainer = {
        backgroundColor:'#E5E5E5',
        minHeight:'100vh',
        padding:20,
        width:'100%'
    }
    const headerBarStyle = {
        backgroundColor:'white',
        borderRadius:16,
        padding:20,
        justifyContent:'center',
        fontWeight:'600',
        fontSize:35,
        marginBottom:20
    }
    const headerRowStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:8,
        paddingLeft:25,
        justifyContent:'start',
        marginBottom:10,
        flexDirection:'row',
        display:'flex',
      }
      const headerItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const rowStyle = {
        backgroundColor:'white',
        borderRadius:8,
        padding:8,
        paddingLeft:25,
        justifyContent:'start',
        marginTop:8,
        flexDirection:'row',
        display:'flex'
      }
      const rowItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const subRowItemStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:25,
        marginRight:10,
        paddingBlock:6,
        paddingInline:10
      }
      const statusAvailable = {
        backgroundColor:'#DDFFC2',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#53BC00'
      }
      const statusUnavailable = {
        backgroundColor:'#FFE8E8',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#BC0000'
      }
      const basicDetailsInnerContainer = {
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'flex-start'
      }
      const permissionsListContainer = {
        backgroundColor:'white',
        borderRadius:8,
        padding:20,
        marginBottom:20
      }
      const actionListContainer = {
        backgroundColor:'#F4F7FE',
        borderRadius:8,
        padding:10,
        marginBottom:20
      }
      const pageListContainer = {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        marginLeft:8,
        flexWrap:'wrap'
      }
      const page = {
        marginLeft:8
      }
      const checkbox = {
        marginRight:10
      }
      const buttonStyle = {
        backgroundColor:'#012C63',
        fontSize:18,
        borderRadius:8,
        paddingBlock:10,
        paddingInline:16.5,
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        display:'flex'
      }

  return (
    <div className='row support_row' >
        <div className='col-lg-2 col-md-2 support_col' >
    <Supportsidenav />
        </div>
        <div className='col-lg-10 col-md-10 support_col' >
        <div style={currentPageContainer} >
            <div style={headerBarStyle} >
              Edit/Create New Role
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Basic Details
             </h5>
            </div>

            <div style={permissionsListContainer} >
              <div style={basicDetailsInnerContainer} >
                
                <div style={{marginRight:20}} >
                  <p style={{fontSize:12}} >
                    Role Name<span style={{color:'red'}} >*</span>
                  </p>
                  <input placeholder='Add role name' type='text' defaultValue={name}  onChange={(txt)=>{
                    setName(txt.target.value)
                    console.log(txt.target.value)
                    }}
                     style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37}} />
                    
                </div>

                {/* <div style={{marginRight:20,}} >
                  <p style={{fontSize:12}} >
                    Description
                  </p>
                  <input type='text' placeholder='Add description of role' onChange={(txt)=>setDescription(txt)} style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37}} />

                </div> */}


              </div>
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
             Pages And Actions
             </h5>
            </div>

            <div style={permissionsListContainer} >
              {allPagesAndActionsList.map((item,index)=>{
                return(
              <div key={index} style={actionListContainer} >
                 {/* <input
          type="checkbox"
         
          style={checkbox}
          // checked={isChecked}
          // onChange={handleOnChange}
        /> */}
                  {item.page}
                  <div style={pageListContainer} >
                  {item.actions.map((itm,i)=>{
                    return(
                      <div key={i} style={page} >
                        <input
          type="checkbox"
          
          style={checkbox}
          defaultChecked={itm.is_enabled}
          onChange={()=>handleOnChange(itm)}
        />
                        {itm.action}{}
                        </div>
                    )
                  })}
                  <button style={{...buttonStyle,margin:10,paddingInline:10,fontSize:12,paddingBlock:4}}
                  onClick={()=>
                    // addNewPageOrAction(item.page,'new action')
                    Swal.fire({
                      title: 'Enter Action Name',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off'
                      },
                      showCancelButton: true,
                      confirmButtonText: 'Enter',
                      showLoaderOnConfirm: true,
                      preConfirm: (action) => {
                      addNewPageOrAction(item.page,action)
                      },
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
  
                    })
                    // console.log('pressed')
                  }
                  >
                     Add New Action
                  </button>
                  </div>
              </div>
                )
              })
              }
             <button style={{...buttonStyle,margin:10,paddingInline:10,fontSize:12,paddingBlock:4}}
                  onClick={()=>
                  // addNewPageOrAction(false,'new page2','All')
                  Swal.fire({
                    title: 'Enter Page Name',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Enter',
                    showLoaderOnConfirm: true,
                    preConfirm: (page) => {
                    addNewPageOrAction(page,'All')
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                  }).then((result) => {

                  })
                  }>
                     Add New Page
                  </button>

            </div>

            <div style={headerBarStyle} >
            <button
            onClick={()=>updateRole()}
              style={{...buttonStyle,width:143}} >
                Update Role
              </button>
            </div>

        </div>

    </div>
    </div>
  )
}
