import { Component, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protected";
import QuestionSearch from "./Teacher/QuestionSearch.";
// import SearchQuestion from './Teacher/questionsearch/SearchQuestion';
// import AllPaymentDetails_School from './School/paymentmodule/AllPaymentDetails_School';
// import Payment_Reciept_School from './School/paymentmodule/Payment_Reciept_School';
import Users from './support/usersAndPermissions/Users'
import Permissions from './support/usersAndPermissions/Permissions'
import Roles from './support/usersAndPermissions/Roles'
import CreateNewRole from './support/usersAndPermissions/CreateNewRole'
import CreateNewUser from './support/usersAndPermissions/CreateNewUser'
import EditRolePermissions from './support/usersAndPermissions/EditRolePermissions'
import "./App.css";
import { CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";

const ErrorPage = lazy(() => import("./errorpage"));
const AllPaymentDetails_School = lazy(() =>
  import("./School/paymentmodule/AllPaymentDetails_School")
);
const Payment_Reciept_School = lazy(() =>
  import("./School/paymentmodule/Payment_Reciept_School")
);

const FrontLand = lazy(() => import("./land/land"));
const Whoweare = lazy(() => import("./land/whoweare"));
const Blankpage = lazy(() => import("./blank"));
const Landing = lazy(() => import("./landing/landing"));
const CustomerURL = lazy(() => import("./School/feesmodule/customerurl"));
const CustomerURLPaymentLink = lazy(() => import("./School/feesmodule/customerUrlPaymentLink"));
const Recipt = lazy(() => import("./School/recipt"));
const TokenAmount = lazy(() => import("./School/feesmodule/TokenAmount.js"));
const TokenAmountRecipt = lazy(() =>
  import("./School/feesmodule/TokenAmountRecipt.js")
);
const TokenAmountStatus = lazy(() =>
  import("./School/feesmodule/TokenAmountStatus.js")
);
const InstallmentAmount = lazy(() =>
  import("./School/feesmodule/InstallmentAmount.js")
);
const InstallmentAmountStatus = lazy(() =>
  import("./School/feesmodule/InstallmentAmountStatus.js")
);
const EditInsDates = lazy(() =>
  import("./support/section module/EditInsDates")
);

//Miscllenous
const ZestResults = lazy(() => import("./Miscllenous/ZestResults.js"));
//Permission
const Support_permission = lazy(() =>
  import("./support/SupportUser/Support_permission")
);

//-------------------------------Student module pages
const AssignmetsSubjects = lazy(() =>
  import("./Student/assignments/AssignmentsSubjects")
);
const Student = lazy(() => import("./Student/student"));
const ResetPassword = lazy(() => import("./Student/resetpassword"));
//feeportal
const Feeportal = lazy(() => import("./Student/Feeportal"));
const PayNow = lazy(() => import("./Student/PayNow"));
const Installment = lazy(() => import("./Student/installments"));
//objectivemodule
const Objectivetest = lazy(() => import("./Student/objectivetest"));
const ObjectiveExamInstruction = lazy(() =>
  import("./Student/objectiveexaminstruction")
);
const Objectiveexam = lazy(() => import("./Student/objectiveexam"));
const ObjectiveExamComplete = lazy(() =>
  import("./Student/objectiveexamcomplete.js")
);
//objectiveexamresult
const PercentileAndScore = lazy(() =>
  import("./Student/objective result/PercentileAndScore.js")
);
const DifficultyLevelWiseScore = lazy(() =>
  import("./Student/objective result/DifficultyLevelWiseScore.js")
);
const TimePerQuestion = lazy(() =>
  import("./Student/objective result/TimePerQuestion.js")
);
const SolutionsObjectiveResult = lazy(() =>
  import("./Student/objective result/Solutions.js")
);
const ChapterWiseScore = lazy(() =>
  import("./Student/objective result/ChapterWiseScore.js")
);
//liveclass
const PastLiveClass = lazy(() =>
  import("./Student/live class/PastLiveClass.js")
);
const UpcomingLiveClass = lazy(() =>
  import("./Student/live class/UpcomingLiveClass.js")
);

const PastLiveClassStudent = lazy(() =>
  import("./Student/pastrecordings/PastLiveClass")
);

//assignment module
const StudentAssignment = lazy(() => import("./Student/studentassignment"));
const AssignmentSubmit = lazy(() => import("./Student/AssignmentSubmit"));
const SubmittedAssignment = lazy(() => import("./Student/SubmitedAssignment"));
const UpdateSubmittedAssignment = lazy(() =>
  import("./Student/UpdateSubmittedAssignment")
);
//video module
const VideoLectures = lazy(() => import("./Student/videomodule"));
const SubjectVideoLands = lazy(() => import("./Student/subjectvideosland"));
const VideoPlayer = lazy(() => import("./Student/videoplayer"));
//doubtmodule
const StudentDoubt = lazy(() => import("./Student/doubtmodule/studentdoubt"));
const DoubtSolution = lazy(() => import("./Student/doubtmodule/doubtsolution"));
const UploadDoubtStudent = lazy(() =>
  import("./Student/doubtmodule/UploadDoubt.js")
);
const ListOfChaptersStudentDoubt = lazy(() =>
  import("./Student/doubtmodule/ListOfChapters.js")
);
const ListOfQuestionsStudentDoubt = lazy(() =>
  import("./Student/doubtmodule/ListOfQuestions.js")
);
const QuestionDetailsStudentDoubt = lazy(() =>
  import("./Student/doubtmodule/QuestionDetails.js")
);
const PendingDoubts = lazy(() =>
  import("./Student/doubtmodule/PendingDoubts.js")
);
//notes
const NotesSubjects = lazy(() => import("./Student/notes/NotesSubjects.js"));
const NotesChapters = lazy(() => import("./Student/notes/NotesChapters.js"));
const ListOfNotes = lazy(() => import("./Student/notes/ListOfNotes.js"));
const ReadNotes = lazy(() => import("./Student/readnotes"));
const ReadSubjectNotes = lazy(() => import("./Student/readsubjectnotes"));
const NoteViewer = lazy(() => import("./Student/noteviewer"));
//profile
const Profile = lazy(() => import("./Student/profile/Profile.js"));
const ChangePassword = lazy(() =>
  import("./Student/profile/ChangePassword.js")
);
//feemodule
const InstallmentPageStudent = lazy(() =>
  import("./Student/feemodule/InstallmentPage.js")
);
const PaymentStudent = lazy(() => import("./Student/feemodule/Payment.js"));
const PaymentStatusStudent = lazy(() =>
  import("./Student/feemodule/PaymentStatus.js")
);

//------------------------school module
const School = lazy(() => import("./School/school"));
//student handling
const RegisterStudent = lazy(() =>
  import("./School/studentmodule/registerstudent")
);
const Studentlist = lazy(() => import("./School/studentmodule/studentlist"));

// import TransferSection_StudentSchool from './School/studentmodule/TransferSection_Student';
const StudentEdit_Details = lazy(() =>
  import("./School/studentmodule/StudentEditDetails")
);
const ParentEditDetails = lazy(() =>
  import("./School/studentmodule/ParentEditDetails.js")
);
// import AddStudentSubjectBindSchool from './School/studentmodule/AddStudentSubjectBind';
// import StudentEdit_Details from './School/studentmodule/StudentDetails';
const TransferSection_StudentSchool = lazy(() =>
  import("./School/studentmodule/TransferSection_Student")
);
const AddStudentSubjectBindSchool = lazy(() =>
  import("./School/studentmodule/AddStudentSubjectBind")
);

const ReceiveFeeSchool = lazy(() =>
  import("./School/studentmodule/ReceiveFee.js")
);

// const CreateInstallmentSchool = lazy(() =>
//   import("./School/studentmodule/CreateInstallment.js")
// );
// const FeeSummarySchool = lazy(() =>
//   import("./School/studentmodule/FeeSummary.js")
// );

const PayDetailsSchool = lazy(() =>
  import("./School/studentmodule/PayDetails.js")
);

const FeeSummarySchool = lazy(() => import("./School/studentmodule/FeeSummary"));
const PaymentDetailsSchool = lazy(() => import("./School/studentmodule/PaymentDetails"));
const PaymentSuccessSchool = lazy(() => import("./School/studentmodule/PaymentSuccess"));
const PaymentFailedSchool = lazy(() => import("./School/studentmodule/PaymentFailed"));
const Installment_createSchool = lazy(() => import("./School/studentmodule/Installment_create"));
const editInstallmentSchool = lazy(() => import("./School/studentmodule/Installment_edit"));

const PaymentDetailsSchoolHostel = lazy(() => import("./School/studentmodule/PaymentDetailsHostel"));
const PaymentSuccessSchoolHostel = lazy(() => import("./School/studentmodule/PaymentSuccessHostel"));
const PaymentFailedSchoolHostel = lazy(() => import("./School/studentmodule/PaymentFailedHostel"));
const InstallmentCreateSchoolHostel = lazy(() => import("./School/studentmodule/InstallmentCreateHostel"));
const InstallmentEditSchoolHostel = lazy(() => import("./School/studentmodule/InstallmentEditHostel"));

const StudentDetailsSchool = lazy(() =>
  import("./School/studentmodule/StudentDetails.js")
);

//teacher handling
const Registerteacher = lazy(() =>
  import("./School/teachermodule/registerteacher")
);
const Teacherlist = lazy(() => import("./School/teachermodule/teacherlist"));
const SetTeacherPermission = lazy(() =>
  import("./School/teachermodule/setteacherpermission")
);
//assignment module
const Assignmentmodule = lazy(() =>
  import("./School/assignmentmodule/assingmentmodule")
);
const AddAssignment = lazy(() =>
  import("./School/assignmentmodule/addassignment")
);
const Assignmentlist = lazy(() =>
  import("./School/assignmentmodule/assignmentlist")
);
const Editassignment = lazy(() =>
  import("./School/assignmentmodule/editassignment")
);
//objectiveexam module
const Objectivetestmodule = lazy(() =>
  import("./School/objectivemodule/objectivetestmodule")
);
const Scheduleobjectiveexam = lazy(() =>
  import("./School/objectivemodule/scheduleobjectiveexam")
);
const Addobjexamsubject = lazy(() =>
  import("./School/objectivemodule/addobjexamsubject")
);
const Addobjexamchapter = lazy(() =>
  import("./School/objectivemodule/addobjexamchapter")
);
const Addobjexamquestion = lazy(() =>
  import("./School/objectivemodule/addobjexamquestion")
);
const AddObjectiveques = lazy(() =>
  import("./School/objectivemodule/addobjectiveques")
);
const ListofExam = lazy(() => import("./School/objectivemodule/listofexam"));
const Editexamdetails = lazy(() =>
  import("./School/objectivemodule/editexamdetails")
);
const ListofQuestionsSchool = lazy(() =>
  import("./School/objectivemodule/ListOfQuestions.js")
);
const ListofExamQuestionsSchool = lazy(() =>
  import("./School/objectivemodule/ListOfExamQuestions.js")
);
const ViewExamDetailsSchool = lazy(() =>
  import("./School/objectivemodule/ViewExamDetails.js")
);
//subjectiveexam module
const Subjectivetestmodule = lazy(() =>
  import("./School/subjectivemodule/subjectivetestmodule")
);
const Addsubjectivepaper = lazy(() =>
  import("./School/subjectivemodule/addsubjectivepaper")
);
const Addsubjectivepaperquestions = lazy(() =>
  import("./School/subjectivemodule/addsubjectivepaperquestions")
);
const Schedulesubjectivepaper = lazy(() =>
  import("./School/subjectivemodule/schedulesubjectivepaper")
);
const ListofScheduledPaper = lazy(() =>
  import("./School/subjectivemodule/listofscheduledpaper")
);
const Paperlist = lazy(() => import("./School/subjectivemodule/paperlist"));
const Editschedule = lazy(() =>
  import("./School/subjectivemodule/editschedule")
);
const Editsubjectivepaper = lazy(() =>
  import("./School/subjectivemodule/editsubjectivepaper")
);
//Feeportal
const schoolFeeportal = lazy(() =>
  import("./School/feesmodule/schoolFeeportal")
);
const schoolPayNow = lazy(() => import("./School/feesmodule/schoolPayNow"));
const schoolInstallment = lazy(() =>
  import("./School/feesmodule/schoolinstallments")
);
const schoolPayNowCheque = lazy(() =>
  import("./School/feesmodule/schoolPayNowCheque")
);
const schoolPayNowPDC = lazy(() =>
  import("./School/feesmodule/schoolPayNowPDC")
);
const schoolPayNowCash = lazy(() =>
  import("./School/feesmodule/schoolPayNowCash")
);
const schoolPayNowCard = lazy(() =>
  import("./School/feesmodule/schoolPayNowCard")
);
const Schoolblank = lazy(() => import("./School/schoolblank"));
//liveclassmodule
const liveClassModule = lazy(() =>
  import("./School/liveclassmodule/liveclassmodule")
);
const ScheduleLive = lazy(() =>
  import("./School/liveclassmodule/schedulelive")
);
const ListOfLiveClassSchool = lazy(() =>
  import("./School/liveclassmodule/ListOfLiveClass.js")
);
const LiveClassAttendanceSchool = lazy(() =>
  import("./School/liveclassmodule/LiveClassAttendance.js")
);
const AddLiveClassLinkingSchool = lazy(() =>
  import("./School/liveclassmodule/AddLiveClassLinking.js")
);
const EditLiveClassSchool = lazy(() =>
  import("./School/liveclassmodule/EditLiveClass.js")
);
const PastLiveClassSchool = lazy(() =>
  import("./School/liveclassmodule/PastLiveClass.js")
);
const ViewLiveClassAssignmentsSchool = lazy(() =>
  import("./School/liveclassmodule/ViewLiveClassAssignments.js")
);

const Support = lazy(() => import("./support/support.js"));
const Uploadvideo = lazy(() => import("./support/uploadvideo"));
const Uploadquestion = lazy(() => import("./support/questionupload"));
//tag
const Tagmoduleland = lazy(() => import("./support/tag module/tagmoduleland"));
const Addatag = lazy(() => import("./support/tag module/addatag"));
const Taglist = lazy(() => import("./support/tag module/taglist"));
const EditTag = lazy(() => import("./support/tag module/EditTag.js"));
//school
const SchoolModule = lazy(() => import("./support/school module/schoolmodule"));
const AddSchool = lazy(() => import("./support/school module/addschool"));
const Schoollist = lazy(() => import("./support/school module/schoollist"));
//city
const AddCity = lazy(() => import("./support/AddCity"));
//section
const SectionModule = lazy(() =>
  import("./support/section module/SectionModule")
);
const AddSection = lazy(() => import("./support/section module/AddSection"));
const AddInstallment = lazy(() =>
  import("./support/section module/addinstallments")
);
const ListOfSections = lazy(() =>
  import("./support/section module/ListOfSections")
);
const EditSection = lazy(() =>
  import("./support/section module/EditSection.js")
);
const BindSectionSchool = lazy(() =>
  import("./support/section module/BindSectionSchool.js")
);
const AddBind = lazy(() => import("./support/section module/AddBind.js"));
const ListOfBinds = lazy(() =>
  import("./support/section module/ListOfBinds.js")
);
const EditBind = lazy(() => import("./support/section module/EditBind.js"));
//payment module
// const AllPaymentDetails_School = lazy(() => import( "./School/paymentmodule/AllPaymentDetails_School"));
// const Payment_Reciept_School = lazy(() => import( "./School/paymentmodule/Payment_Reciept_School"));
//class
const ClassModule = lazy(() => import("./support/class module/ClassModule.js"));
const AddClass = lazy(() => import("./support/class module/AddClass.js"));
const ListOfClasses = lazy(() =>
  import("./support/class module/ListOfClasses.js")
);
const EditClass = lazy(() => import("./support/class module/EditClass.js"));
const BindClassSubject = lazy(() =>
  import("./support/class module/BindClassSubject.js")
);

//Payment
//subject
const SubjectModule = lazy(() =>
  import("./support/subject module/SubjectModule.js")
);
const AddSubject = lazy(() => import("./support/subject module/AddSubject.js"));
const ListOfSubjects = lazy(() =>
  import("./support/subject module/ListOfSubjects.js")
);
const EditSubject = lazy(() =>
  import("./support/subject module/EditSubject.js")
);
//Chapter
const ChapterModule = lazy(() =>
  import("./support/chapter module/ChapterModule.js")
);
const AddChapter = lazy(() => import("./support/chapter module/AddChapter.js"));
const ListOfChapters = lazy(() =>
  import("./support/chapter module/ListOfChapters.js")
);
const EditChapter = lazy(() =>
  import("./support/chapter module/EditChapter.js")
);
//objective
const ObjectiveModule = lazy(() =>
  import("./support/objective module/ObjectiveModule.js")
);
const StudentExamResult = lazy(() =>
  import("./support/objective module/StudentExamResult")
);
const ScheduleObjectiveExam = lazy(() =>
  import("./support/objective module/ScheduleObjectiveExam.js")
);
const AddExamSubject = lazy(() =>
  import("./support/objective module/AddExamSubject.js")
);
const AddExamChapter = lazy(() =>
  import("./support/objective module/AddExamChapter.js")
);
const AddExamQuestion = lazy(() =>
  import("./support/objective module/AddExamQuestion.js")
);
const ListOfExams = lazy(() =>
  import("./support/objective module/ListOfExams.js")
);
const EditScheduleExam = lazy(() =>
  import("./support/objective module/EditScheduleExam.js")
);
const ViewExamDetails = lazy(() =>
  import("./support/objective module/ViewExamDetails.js")
);
const AddQuestion = lazy(() =>
  import("./support/objective module/AddQuestion.js")
);
const AddIntegerQuestion = lazy(() =>
  import("./support/objective module/AddIntegerQuestion.js")
);
const ListofQuestions_Obj = lazy(() =>
  import("./support/objective module/ListofQuestions_Obj")
);
const ListofIntegerQuestions = lazy(() =>
  import("./support/objective module/ListofIntegerQuestions")
);
const ListofExamQues_Obj = lazy(() =>
  import("./support/objective module/ListofExamQues_Obj")
);
const Schedule_LiveClasslist = lazy(() =>
  import("./support/LiveClass/Schedule_LiveClasslist")
);
const Schedule_LiveClassLinking = lazy(() =>
  import("./support/LiveClass/Schedule_LiveClassLinking")
);
const EditSchedule_liveclass = lazy(() =>
  import("./support/LiveClass/EditSchedule_liveclass")
);
const LiveAtten = lazy(() => import("./support/LiveClass/LiveAtten"));
const PastLive_Class = lazy(() => import("./support/LiveClass/PastLive_Class"));
const LiveClassAssign = lazy(() =>
  import("./support/LiveClass/LiveClassAssign")
);
const Schedule_LiveHome = lazy(() =>
  import("./support/LiveClass/Schedule_LiveHome")
);
const Schedule_LiveClass = lazy(() =>
  import("./support/LiveClass/Schedule_LiveClass")
);
//Promo Code
const AddPromo = lazy(() => import("./support/PromoCode/AddPromo"));
const PromoHome = lazy(() => import("./support/PromoCode/PromoHome"));
//doubt
const DoubtModule = lazy(() => import("./support/doubt module/DoubtModule.js"));
const MasterTeachers = lazy(() => import("./support/doubt module/MasterTeachers.js"));
const BookModule = lazy(() => import("./support/doubt module/BookModule.js"));
const ChapterModuleDoubt = lazy(() =>
  import("./support/doubt module/ChapterModule.js")
);
const QuestionModuleDoubt = lazy(() =>
  import("./support/doubt module/QuestionModule.js")
);
const AddBook = lazy(() => import("./support/doubt module/AddBook.js"));
const ListOfBooks = lazy(() => import("./support/doubt module/ListOfBooks.js"));
const EditBook = lazy(() => import("./support/doubt module/EditBook.js"));
const AddChapterDoubt = lazy(() =>
  import("./support/doubt module/AddChapter.js")
);
const ListOfChaptersDoubt = lazy(() =>
  import("./support/doubt module/ListOfChapters.js")
);
const EditChapterDoubt = lazy(() =>
  import("./support/doubt module/EditChapter.js")
);
const AddQuestionDoubt = lazy(() =>
  import("./support/doubt module/AddQuestion.js")
);
const ListOfQuestionsDoubt = lazy(() =>
  import("./support/doubt module/ListOfQuestions.js")
);
const EditQuestionDoubt = lazy(() =>
  import("./support/doubt module/EditQuestion.js")
);
const TeacherModuleDoubt = lazy(() =>
  import("./support/doubt module/TeacherModule.js")
);
const AddTeacherDoubt = lazy(() =>
  import("./support/doubt module/AddTeacher.js")
);
const ListOfTeachersDoubt = lazy(() =>
  import("./support/doubt module/ListOfTeachers.js")
);
const SearchQuestionDoubt = lazy(() =>
  import("./support/doubt module/SearchQuestion.js")
);
//data entry user
const DEUModule = lazy(() =>
  import("./support/data entry user module/DEUModule.js")
);
const AddDEU = lazy(() => import("./support/data entry user module/AddDEU.js"));
const ListOfDEU = lazy(() =>
  import("./support/data entry user module/ListOfDEU.js")
);
//notes
const NotesModule = lazy(() => import("./support/notes module/NotesModule.js"));
const AddNotesSupport = lazy(() =>
  import("./support/notes module/AddNotes.js")
);
const ListOfNotesSupport = lazy(() =>
  import("./support/notes module/ListOfNotes.js")
);
const EditNotesSupport = lazy(() =>
  import("./support/notes module/EditNotes.js")
);

const ImportInstallment = lazy(() =>
  import("./support/student module/ImportInstallment.js")
);

const editInstallment = lazy(() =>
  import("./support/student module/Installment_edit.js")
);

const PaymentDetails = lazy(() =>
  import("./support/student module/PaymentDetails.js")
);

const ImportExam = lazy(() =>
  import("./support/objective module/ImportExam.js")
);

//student

// import StudentModuleSupport from './support/student module/StudentModule.js';
// import AddStudentSupport from './support/student module/AddStudent.js';
// import StudentDetails from './support/student module/StudentDetails.js';
// import  from './support/student module/StudentDetails.js';
// import EditStudentDetails from'./support/student module/EditStudentDetails';
// import ListOfStudentsSupport from './support/student module/ListOfStudents.js';
// import ListOfStudentSubjectBind from './support/student module/ListOfStudentSubjectBind.js';
// import AddStudentSubjectBind from './support/student module/AddStudentSubjectBind.js';
// import StudentResultSupport from './support/student module/StudentResult.js';
// import FeeSummary from './support/student module/FeeSummary';
// import PayDetails from './support/student module/PayDetails';
// import Installment_create from './support/student module/Installment_create';

const StudentModuleSupport = lazy(() =>
  import("./support/student module/StudentModule.js")
);
const AddStudentSupport = lazy(() =>
  import("./support/student module/AddStudent.js")
);
const StudentDetails = lazy(() =>
  import("./support/student module/StudentDetails.js")
);
const EditStudentDetails = lazy(() =>
  import("./support/student module/EditStudentDetails")
);
const EditParentDetails = lazy(() =>
  import("./support/student module/EditParentDetails.js")
);
const ListOfStudentsSupport = lazy(() =>
  import("./support/student module/ListOfStudents.js")
);
const ListOfStudentSubjectBind = lazy(() =>
  import("./support/student module/ListOfStudentSubjectBind.js")
);
const AddStudentSubjectBind = lazy(() =>
  import("./support/student module/AddStudentSubjectBind.js")
);
const StudentResultSupport = lazy(() =>
  import("./support/student module/StudentResult.js")
);
const FeeSummary = lazy(() => import("./support/student module/FeeSummary"));
const PayDetails = lazy(() => import("./support/student module/PayDetails"));
const PaymentSuccess = lazy(() => import("./support/student module/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./support/student module/PaymentFailed"));
const Installment_create = lazy(() => import("./support/student module/Installment_create"));


const PaymentDetailsHostel = lazy(() => import("./support/student module/PaymentDetailsHostel"));
const PaymentSuccessHostel = lazy(() => import("./support/student module/PaymentSuccessHostel"));
const PaymentFailedHostel = lazy(() => import("./support/student module/PaymentFailedHostel"));
const InstallmentCreateHostel = lazy(() => import("./support/student module/InstallmentCreateHostel"));
const InstallmentEditHostel = lazy(() => import("./support/student module/InstallmentEditHostel"));
const UpgradeStudent = lazy(() =>
  import("./support/student module/UpgradeStudent")
);

const UpgradeStudentSchoolSide = lazy(() =>
  import("./School/studentmodule/UpgradeStudent")
);

// create user
const Create_userHome = lazy(() =>
  import("./support/SupportUser/Create_userHome")
);

//----------------------teacher module
const Teacher = lazy(() => import("./Teacher/teacher"));

// unverified questions module
const UnverifiedQuestions = lazy(() => import("./Teacher/unverifiedQuestions"));
const UnverifiedQuestionsChapter = lazy(() => import("./Teacher/unverifiedQuestions/questions"));
const CompareQuestions = lazy(() => import("./Teacher/unverifiedQuestions/compareQuestions"));


//nodes
const AddNotes = lazy(() => import("./Teacher/addnotes"));
const NotesList = lazy(() => import("./Teacher/noteslist"));
const EditNotes = lazy(() => import("./Teacher/editnotes"));
const ActionsPage = lazy(() => import("./Teacher/ActionsPage"));
//Myschedule
const MySchedule = lazy(() => import("./Teacher/myschedule/MySchedule"));

//pastliveclass
const MyPastLiveClasses = lazy(() =>
  import("./Teacher/pastclasses/MyPastLiveClasses")
);

// doubt
const DoubtModuleTeacher = lazy(() =>
  import("./Teacher/doubt module/DoubtModule.js")
);
const ListOfAllotedDoubtsTeacher = lazy(() =>
  import("./Teacher/doubt module/ListOfAllotedDoubts.js")
);
const AddDEUTeacher = lazy(() => import("./Teacher/doubt module/AddDEU.js"));
const EditAllotedDoubtTeacher = lazy(() =>
  import("./Teacher/doubt module/EditAllotedDoubt.js")
);
const SolveDoubt = lazy(() => import("./Teacher/doubt module/SolveDoubt.js"));

//-------------------------DoubtTeacher
const DoubtTeacher = lazy(() => import("./DoubtTeacher/DoubtTeacher.js"));
const ListOfPendingDoubts = lazy(() =>
  import("./DoubtTeacher/ListOfPendingDoubts.js")
);
const AllocateDoubt = lazy(() => import("./DoubtTeacher/AllocateDoubt.js"));
const ListOfAllocatedDoubts = lazy(() =>
  import("./DoubtTeacher/ListOfAllocatedDoubts.js")
);

//-------------------------Parent
// const Parent = lazy(() => import("./Parent/Parent"));

//------------------------------DataEntryUser
const DTE = lazy(() => import("./DTE/DTE.js"));
const ListOfAllotedDoubtsDTE = lazy(() =>
  import("./DTE/ListOfAllotedDoubts.js")
);
const EditAllotedDoubtDTE = lazy(() => import("./DTE/EditAllotedDoubt.js"));
const AddToDB = lazy(() => import("./DTE/AddToDB.js"));
const PromoList = lazy(() => import("./support/PromoCode/PromoList"));
const Create_SupportUser = lazy(() =>
  import("./support/SupportUser/Create_SupportUser")
);
const SupportUserList = lazy(() =>
  import("./support/SupportUser/SupportUserList")
);
const CreateTeacher = lazy(() => import("./support/SupportUser/CreateTeacher"));
const ListofTeacher_cre = lazy(() =>
  import("./support/SupportUser/ListofTeacher_cre")
);
const Studentlist_school = lazy(() =>
  import("./support/school module/Studentlist_school")
);
const EditSchool = lazy(() => import("./support/school module/EditSchool"));
const AddBindButton = lazy(() =>
  import("./support/school module/AddBindButton")
);
const AllPaymentDetails = lazy(() =>
  import("./support/PromoCode/AllPaymentDetails")
);
const PendingCheque = lazy(() => import("./support/PromoCode/PendingCheque"));
const PendingTransfer = lazy(() =>
  import("./support/PromoCode/PendingTransfer")
);
const UpdateCheck = lazy(() => import("./support/PromoCode/UpdateCheck"));
const UpdateTransfer = lazy(() => import("./support/PromoCode/UpdateTransfer"));
const ReciveFee = lazy(() => import("./support/student module/ReciveFee"));
const SupportDeletePer = lazy(() =>
  import("./support/SupportUser/SupportDeletePer")
);
const TransferSchool_Student = lazy(() =>
  import("./support/student module/TransferSchool_Student")
);
const TransferSection_Student = lazy(() =>
  import("./support/student module/TransferSection_Student")
);
const StudentsBindlist = lazy(() =>
  import("./support/section module/StudentsBindlist")
);
const Mobile = lazy(() => import("./ForgotPassword/Mobile"));
const Password = lazy(() => import("./ForgotPassword/Password"));
const Invoice = lazy(() => import("./School/studentmodule/Invoice"));
const EditInstallment = lazy(() =>
  import("./School/studentmodule/EditInstallment")
);
const Feesummary = lazy(() => import("./Student/Feesummary"));
const ChangePass_Support = lazy(() =>
  import("./support/SupportUser/ChangePass_Support")
);
const ChangePass_School = lazy(() => import("./School/ChangePass_School"));
const Student_Report = lazy(() =>
  import("./support/student module/Student_Report")
);
const Listofexams2 = lazy(() =>
  import("./support/objective module/Listofexams2")
);
const StudentResult_View = lazy(() =>
  import("./support/objective module/StudentResult_View")
);
const jeepage = lazy(() => import("./landing/Jeepage"));
const PaymentLink = lazy(() => import("./support/PromoCode/PaymentLink"));
const PaidRegistration = lazy(() =>
  import("./support/PromoCode/PaidRegistration")
);
const Editquestion_Obj = lazy(() =>
  import("./support/objective module/Editquestion_Obj")
);
const EditInstallment_support = lazy(() =>
  import("./support/student module/EditInstallment_support")
);
const Myschedule = lazy(() => import("./Student/Myschedule"));
const Timetable = lazy(() => import("./support/LiveClass/Timetable"));
const Timetablelist = lazy(() => import("./support/LiveClass/Timetablelist"));
const Studymaterial = lazy(() => import("./support/LiveClass/Studymaterial"));
const Studymateriallist = lazy(() =>
  import("./support/LiveClass/Studymateriallist")
);
const StudentExamResult_school = lazy(() =>
  import("./School/objectivemodule/StudentExamResult_school")
);
const AttendenceReport = lazy(() =>
  import("./support/LiveClass/AttendenceReport")
);
const ImportTag = lazy(() => import("./support/tag module/importTag"));
const AssignmentsList_student = lazy(() =>
  import("./Student/assignments/AssignmentsList_Student")
);
const { ProcessingInstruction } = lazy(() => import("html-react-parser"));
const AssignmentsChapters = lazy(() =>
  import("./Student/assignments/AssignmentsChapters.js")
);
const Payment_Recipt = lazy(() => import("./support/PromoCode/Payment_Recipt"));

const Payment_Recipt_New = lazy(() => import("./support/PromoCode/PaymentReciptNew"));
const Payment_Recipt_installment = lazy(() =>
  import("./support/PromoCode/PaymentRecipt_Installment")
);

const ChangePasswordTeacher = lazy(() =>
  import("./Teacher/ChangePasswordTeacher")
);
const ChangePasswordSupport = lazy(() =>
  import("./support/ChangePasswordSupport")
);
const Payment_successPage = lazy(() =>
  import("./component/Payment_successPage")
);
const SchoolAttendance = lazy(() =>
  import("./support/objective module/SchoolAttendance")
);
const AttendanceReport_School = lazy(() =>
  import("./School/AttendanceReport_School")
);
const PaymentRecipt_feesummmary = lazy(() =>
  import("./support/student module/PaymentRecipt_feesummmary")
);
const PaymentReciptHostelSummary = lazy(() => import("./support/student module/PaymentReciptHostelSummary"));
const Recipt_feesummary = lazy(() =>
  import("./School/studentmodule/Recipt_feesummary")
);

const OptionsPage = lazy(() =>
  import("./Teacher/DPPAutomation/OptionsPage")
);

const DppListPage = lazy(() =>
  import("./Teacher/DPPAutomation/DppListPage")
);

const DppCreationPage = lazy(() =>
  import("./Teacher/DPPAutomation/DppCreationPage")
);

const CRM = lazy(() =>
  import("./Superadmin/CRM/CRM")
);


{/* User Permissions and Page Actions */ }

// const Users = lazy(()=> import('./support/usersAndPermissions/Users'))
// const Permissions = lazy(()=> import('./support/usersAndPermissions/Permissions'))
// const Roles = lazy(()=> import('./support/usersAndPermissions/Roles'))
// const CreateNewRole = lazy(()=> import('./support/usersAndPermissions/CreateNewRole'))
// const CreateNewUser = lazy(()=> import('./support/usersAndPermissions/CreateNewUser'))






class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Suspense
            fallback={
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <CircularProgress variant="indeterminate" sx={{ color: "#1F3061" }} size={56} thickness={4} />
              </div>
            }
          >
            <ToastContainer style={{ width: "auto" }} autoClose={2500} containerId='main-toast-container' />

            <Switch>
              <Route exact path="/" component={FrontLand} value="Landing" />
              <Route
                exact
                path="/jee_advance_crash_course"
                component={jeepage}
                value="Landing"
              />
              <Route
                exact
                path="/student/PaymentSuccess"
                component={Payment_successPage}
                value="Landing"
              />
              <Route exact path="/error" component={ErrorPage} />
              <Route exact path="/login" component={Landing} />
              <Route exact path="/forgotPassword" component={Mobile} />
              <Route
                exact
                path="/PaidRegisteration/:amount"
                component={PaidRegistration}
              />
              <Route exact path="/ChoosePassword" component={Password} />
              <Route exact path="/whoweare" component={Whoweare} />
              <Route exact path="/blankpage" component={Blankpage} />
              <Route
                exact
                path="/parentpayment/:orderid/:installmentid"
                component={CustomerURL}
              />
              <Route
                exact
                path="/paymentlink/:orderid"
                component={CustomerURLPaymentLink}
              />
              <Route exact path="/paymentrecipt/:order_id" component={Recipt} />
              <Route
                exact
                path="/tokenamount/:tokenid"
                component={TokenAmount}
              />
              <Route
                exact
                path="/tokenamountrecipt"
                component={TokenAmountRecipt}
              />
              <Route
                exact
                path="/tokenamountpaymentstatus/:status"
                component={TokenAmountStatus}
              />
              <Route
                exact
                path="/installmentamount/:installmentid"
                component={InstallmentAmount}
              />
              <Route
                exact
                path="/installmentamountpaymentstatus/:status"
                component={InstallmentAmountStatus}
              />

              <Route exact path="/izest/result" component={ZestResults} />

              <Route
                exact
                path="/pastrecordings/:student_id"
                component={PastLiveClassStudent}
              />

              <ProtectedRoute exact path="/dte" component={DTE} value="DTE" />
              <ProtectedRoute
                exact
                path="/dte/alloteddoubtlist"
                component={ListOfAllotedDoubtsDTE}
                value="DTE"
              />
              <ProtectedRoute
                exact
                path="/dte/editalloteddoubt"
                component={EditAllotedDoubtDTE}
                value="DTE"
              />
              <ProtectedRoute
                exact
                path="/dte/addtodb"
                component={AddToDB}
                value="DTE"
              />

              <ProtectedRoute
                exact
                path="/doubtteacher"
                component={DoubtTeacher}
                value="DoubtTeacher"
              />
              <ProtectedRoute
                exact
                path="/doubtteacher/pendingdoubtslist"
                component={ListOfPendingDoubts}
                value="DoubtTeacher"
              />
              <ProtectedRoute
                exact
                path="/doubtteacher/allocatedoubt"
                component={AllocateDoubt}
                value="DoubtTeacher"
              />
              <ProtectedRoute
                exact
                path="/doubtteacher/allocatedoubtslist"
                component={ListOfAllocatedDoubts}
                value="DoubtTeacher"
              />

              <ProtectedRoute
                exact
                path="/school"
                component={School}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment"
                component={schoolFeeportal}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/installment"
                component={schoolInstallment}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/paynow"
                component={schoolPayNow}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/paynow/cash"
                component={schoolPayNowCash}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/paynow/card"
                component={schoolPayNowCard}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/paynow/cheque"
                component={schoolPayNowCheque}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/payment/paynow/pdc"
                component={schoolPayNowPDC}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/TestResult/:exam_id"
                component={StudentExamResult_school}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/registerstudent"
                component={RegisterStudent}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentlist"
                component={Studentlist}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentlist/studentdetails/:student_id"
                component={StudentDetailsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/receivefee"
                component={ReceiveFeeSchool}
                value="School"
              />
              {/* <ProtectedRoute
                exact
                path="/school/studentpayment/createinstallment/:student_id"
                component={CreateInstallmentSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/feesummary"
                component={FeeSummarySchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/EditInstallment"
                component={EditInstallment}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/studentpayment/paydetails"
                component={PayDetailsSchool}
                value="School"
              /> */}

              <ProtectedRoute
                exact
                path="/school/studentpayment/edit-installment/:student_id"
                component={editInstallmentSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-details/:student_id"
                component={PaymentDetailsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/FeeSummary/:student_id"
                component={FeeSummarySchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-success"
                component={PaymentSuccessSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-failed"
                component={PaymentFailedSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/PayDetails"
                component={PayDetailsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/CreateInstallment/:student_id"
                component={Installment_createSchool}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/studentpayment/edit-installment-hostel/:student_id"
                component={InstallmentEditSchoolHostel}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-details-hostel/:student_id"
                component={PaymentDetailsSchoolHostel}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-success-hostel"
                component={PaymentSuccessHostel}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentpayment/payment-failed-hostel"
                component={PaymentFailedHostel}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/studentpayment/CreateInstallmentHostel/:student_id"
                component={InstallmentCreateSchoolHostel}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/hostel-receipt/:payment_id"
                component={PaymentReciptHostelSummary}
                value="School"
              />


              <ProtectedRoute
                exact
                path="/school/StudentEditDetail"
                component={StudentEdit_Details}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/ParentEditDetail"
                component={ParentEditDetails}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/StudentTransferSection"
                component={TransferSection_StudentSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/Invoice"
                component={Invoice}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/studentmodule/addstudentsubjectbind/:student_id"
                component={AddStudentSubjectBindSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/changePassSchool"
                component={ChangePass_School}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/AttendanceReport_School"
                component={AttendanceReport_School}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/registerteacher"
                component={Registerteacher}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/teacherlist"
                component={Teacherlist}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/setteacherpermission"
                component={SetTeacherPermission}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/addassignmentmodule"
                component={Assignmentmodule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/assignmentmodule/addassignment"
                component={AddAssignment}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/assignmentmodule/assignmentlist"
                component={Assignmentlist}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/assignmentmodule/editassignment"
                component={Editassignment}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/objectivetestmodule"
                component={Objectivetestmodule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/addobjectiveexam"
                component={Scheduleobjectiveexam}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/schedule"
                component={ListofExam}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/addobjectiveques"
                component={AddObjectiveques}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/schedule/addsubject"
                component={Addobjexamsubject}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/schedule/addchapter"
                component={Addobjexamchapter}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/schedule/addquestion"
                component={Addobjexamquestion}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/schedule/editexam"
                component={Editexamdetails}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/questionlist"
                component={ListofQuestionsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/questionlistexam"
                component={ListofExamQuestionsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/objectivetestmodule/viewexamdetails"
                component={ViewExamDetailsSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule"
                component={Subjectivetestmodule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/schedule"
                component={ListofScheduledPaper}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/addsubjectivepaper"
                component={Addsubjectivepaper}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/editsubjectivepaper"
                component={Editsubjectivepaper}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/addsubjectivepaper/addquestions"
                component={Addsubjectivepaperquestions}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/schedulepaper"
                component={Schedulesubjectivepaper}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjectivetestmodule/paperlist"
                component={Paperlist}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/subjecttivetestmodule/schedule/editschedule"
                component={Editschedule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/StudentEditDetails"
                component={StudentEdit_Details}
                value="school"
              />

              <ProtectedRoute
                exact
                path="/school/liveclassesmodule"
                component={liveClassModule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/schedulelive"
                component={ScheduleLive}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/listoflife"
                component={Editschedule}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/liveclasslist"
                component={ListOfLiveClassSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/liveclassattendance"
                component={LiveClassAttendanceSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/addliveclasslinking"
                component={AddLiveClassLinkingSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/editliveclass"
                component={EditLiveClassSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/pastliveclass"
                component={PastLiveClassSchool}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/school/liveclassesmodule/viewliveclassassignment"
                component={ViewLiveClassAssignmentsSchool}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/paymentmodule/allpaymentdetails_school"
                component={AllPaymentDetails_School}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/School/paymentmodule/Payment_Reciept_School/:payment_id"
                component={Payment_Reciept_School}
                value="School"
              />
              <ProtectedRoute
                exact
                path="/School/paymentmodule/Feesummary_Recipt/:payment_id"
                component={Recipt_feesummary}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/school/blank"
                component={Schoolblank}
                value="School"
              />

              <ProtectedRoute
                exact
                path="/support"
                component={Support}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/uploadvideo"
                component={Uploadvideo}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/questionupload"
                component={Uploadquestion}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/tagmodule/tagmoduleland"
                component={Tagmoduleland}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/tagmodule/addatag"
                component={Addatag}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/tagmodule/taglist"
                component={Taglist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/tagmodule/edittag"
                component={EditTag}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/tagmodule/importtag"
                component={ImportTag}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/AddCity"
                component={AddCity}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/centremanagement"
                component={SchoolModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/centremanagement/addcentre"
                component={AddSchool}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/centremanagement/centrelist"
                component={Schoollist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/schoolmodule/Bindbutton"
                component={AddBindButton}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/schoolmodule/StudentList"
                component={Studentlist_school}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/schoolmodule/SchoolEdit"
                component={EditSchool}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule"
                component={SectionModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/addsection"
                component={AddSection}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/addinstallment"
                component={AddInstallment}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/sectionlist"
                component={ListOfSections}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/editsection"
                component={EditSection}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/bindsectionschool"
                component={BindSectionSchool}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/addbind"
                component={AddBind}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/bindlist"
                component={ListOfBinds}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/editbind"
                component={EditBind}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/sectionmodule/Editdates"
                component={EditInsDates}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/classmodule"
                component={ClassModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/classmodule/addclass"
                component={AddClass}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/classmodule/classlist"
                component={ListOfClasses}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/classmodule/editclass"
                component={EditClass}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/classmodule/bindclasssubject"
                component={BindClassSubject}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/subjectmodule"
                component={SubjectModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/subjectmodule/addsubject"
                component={AddSubject}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/subjectmodule/subjectlist"
                component={ListOfSubjects}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/subjectmodule/editsubject"
                component={EditSubject}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/chaptermodule"
                component={ChapterModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/chaptermodule/addchapter"
                component={AddChapter}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/chaptermodule/chapterlist"
                component={ListOfChapters}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/chaptermodule/editchapter/:chapterid/:chapter_name"
                component={EditChapter}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule"
                component={ObjectiveModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/scheduleobjectiveexam"
                component={ScheduleObjectiveExam}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/scheduleobjectiveexam"
                component={ScheduleObjectiveExam}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/objectivemodule/schoolattendance"
                component={SchoolAttendance}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/objectivemodule/schedule/addexamsubject"
                component={AddExamSubject}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/schedule/addexamchapter"
                component={AddExamChapter}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/schedule/addexamquestion"
                component={AddExamQuestion}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/scheduleexamlist"
                component={ListOfExams}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/scheduleexamlist_second"
                component={Listofexams2}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/editscheduleexam"
                component={EditScheduleExam}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/viewexamdetails/:examid"
                component={ViewExamDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/addquestion"
                component={AddQuestion}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/addintegerquestion"
                component={AddIntegerQuestion}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/objectivemodule/ListOFQuestion"
                component={ListofQuestions_Obj}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/ListOFIntegerQuestion"
                component={ListofIntegerQuestions}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/objectivemodule/ListOfQuestion-Exam"
                component={ListofExamQues_Obj}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/ListOfQuestion-Exam/edit/:ques_id"
                component={Editquestion_Obj}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/viewresultdetails/:exam_id"
                component={StudentExamResult}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/objectivemodule/viewresult"
                component={StudentResult_View}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule"
                component={Schedule_LiveHome}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/LiveClass"
                component={Schedule_LiveClass}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/LiveClasslist"
                component={Schedule_LiveClasslist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/LiveClasslinking"
                component={Schedule_LiveClassLinking}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/EditLiveClass"
                component={EditSchedule_liveclass}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/LiveAttendance"
                component={AttendenceReport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/PastliveList"
                component={PastLive_Class}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Schedule/LiveClassAssign"
                component={LiveClassAssign}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/PromoCode/AddPromo"
                component={AddPromo}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/PromoCode"
                component={PromoHome}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/PromoCode/PromoList"
                component={PromoList}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/AllPaymentDetails"
                component={AllPaymentDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/PendingCheque"
                component={PendingCheque}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/PendingTransfer"
                component={PendingTransfer}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/Paymentlink"
                component={PaymentLink}
                value="Support"
              />
              {/* <ProtectedRoute
                exact
                path="/support/Payment_recipt/:payment_id"
                component={Payment_Recipt}
                value="Support"
              /> */}

              {/* Latest Receipt */}
              <ProtectedRoute
                exact
                path="/support/PaymentReceipt/:payment_id"
                component={Payment_Recipt_New}
                value="Support"
              />


              <ProtectedRoute
                exact
                path="/support/Payment_recipt_feesummary/:payment_id"
                component={PaymentRecipt_feesummmary}
                value="Support"
              />
              {/* <ProtectedRoute
                exact
                path="/support/Payment_recipt_feesummary/:payment_id"
                component={PaymentRecipt_feesummmary}
                value="Support"
              /> */}
              <ProtectedRoute
                exact
                path="/support/Payment/UpdateCheque"
                component={UpdateCheck}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/UpdateTransfer"
                component={UpdateTransfer}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/Payment/ReciveFee/:username"
                component={ReciveFee}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/StudentTransferSchool"
                component={TransferSchool_Student}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/EditStudentDetails"
                component={EditStudentDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/EditParentDetails"
                component={EditParentDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/StudentTransferSection"
                component={TransferSection_Student}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/StudentsBindList/:sectionid/:schoolid"
                component={StudentsBindlist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/masterteachers"
                component={MasterTeachers}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/doubtmodule"
                component={DoubtModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/bookmodule"
                component={BookModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/chaptermodule"
                component={ChapterModuleDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/questionmodule"
                component={QuestionModuleDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/addbook"
                component={AddBook}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/booklist"
                component={ListOfBooks}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/editbook"
                component={EditBook}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/addchapter"
                component={AddChapterDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/chapterlist"
                component={ListOfChaptersDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/editchapter"
                component={EditChapterDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/addquestion"
                component={AddQuestionDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/questionlist"
                component={ListOfQuestionsDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/editquestion"
                component={EditQuestionDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/searchquestion"
                component={SearchQuestionDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/teachermodule"
                component={TeacherModuleDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/addteacher"
                component={AddTeacherDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/doubtmodule/teacherlist"
                component={ListOfTeachersDoubt}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/dataentryusermodule"
                component={DEUModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/dataentryusermodule/adddeu"
                component={AddDEU}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/dataentryusermodule/deulist"
                component={ListOfDEU}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/notesmodule"
                component={NotesModule}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/notesmodule/addnotes"
                component={AddNotesSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/notesmodule/noteslist"
                component={ListOfNotesSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/notesmodule/editnotes"
                component={EditNotesSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule"
                component={StudentModuleSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/addstudent"
                component={AddStudentSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/studentlist"
                component={ListOfStudentsSupport}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/studentdetails/:student_id"
                component={StudentDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/studentsubjectbindlist"
                component={ListOfStudentSubjectBind}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/addstudentsubjectbind/:student_id"
                component={AddStudentSubjectBind}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/studentresult"
                component={StudentResultSupport}
                value="Support"
              />
              {/* support routing for fee module start */}
              <ProtectedRoute
                exact
                path="/support/studentmodule/edit-installment/:student_id"
                component={editInstallment}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-details/:student_id"
                component={PaymentDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/FeeSummary/:student_id"
                component={FeeSummary}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-success"
                component={PaymentSuccess}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-failed"
                component={PaymentFailed}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/PayDetails"
                component={PayDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/CreateInstallment/:student_id"
                component={Installment_create}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/edit-installment-hostel/:student_id"
                component={InstallmentEditHostel}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-details-hostel/:student_id"
                component={PaymentDetailsHostel}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-success-hostel"
                component={PaymentSuccessHostel}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/payment-failed-hostel"
                component={PaymentFailedHostel}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/PayDetails"
                component={PayDetails}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studentmodule/CreateInstallmentHostel/:student_id"
                component={InstallmentCreateHostel}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/hostel-receipt/:payment_id"
                component={PaymentReciptHostelSummary}
                value="Support"
              />

              {/* support routing for fee module end */}
              <ProtectedRoute
                exact
                path="/support/CreateUserHome"
                component={Create_userHome}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/SupportUser"
                component={Create_SupportUser}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/SupportUserList"
                component={SupportUserList}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/CreateTeacher"
                component={CreateTeacher}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/ListofTeacher"
                component={ListofTeacher_cre}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/Permissions"
                component={Support_permission}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/DeletePermissions"
                component={SupportDeletePer}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/CreateUserHome/changePassword"
                component={ChangePass_Support}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/StudentsRepot"
                component={Student_Report}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/payment/EditInstallment"
                component={EditInstallment_support}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/timetable"
                component={Timetable}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/timetablelist"
                component={Timetablelist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studymaterial"
                component={Studymaterial}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/studymateriallist"
                component={Studymateriallist}
                value="Support"
              />
              <ProtectedRoute
                exact
                path="/support/ChangePasswordSupport"
                component={ChangePasswordSupport}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/studentmodule/ImportInstallment"
                component={ImportInstallment}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/objectivemodule/ImportExam"
                component={ImportExam}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/student"
                component={Student}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/resetpassword"
                component={ResetPassword}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/assignment"
                component={StudentAssignment}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/submitassignment"
                component={AssignmentSubmit}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/submittedassignment"
                component={SubmittedAssignment}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/updateassignment"
                component={UpdateSubmittedAssignment}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/assignmentslist"
                component={AssignmentsList_student}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/assignmentssubjects"
                component={AssignmetsSubjects}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/assignmentschapters"
                component={AssignmentsChapters}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/videolectures"
                component={VideoLectures}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/subjectvideos"
                component={SubjectVideoLands}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/videoplayer"
                component={VideoPlayer}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/readsubjectnotes"
                component={ReadSubjectNotes}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/readnotes"
                component={ReadNotes}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/notes"
                component={NoteViewer}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/askyourdoubt"
                component={StudentDoubt}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtsolutions"
                component={DoubtSolution}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtmodule/uploaddoubt"
                component={UploadDoubtStudent}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtmodule/chapterlist"
                component={ListOfChaptersStudentDoubt}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtmodule/questionlist"
                component={ListOfQuestionsStudentDoubt}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtmodule/questiondetails"
                component={QuestionDetailsStudentDoubt}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/doubtmodule/pendingdoubts"
                component={PendingDoubts}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/objectivetest"
                component={Objectivetest}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveexaminstruction"
                component={ObjectiveExamInstruction}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveexam"
                component={Objectiveexam}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveexamcomplete"
                component={ObjectiveExamComplete}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveresult/percentileandscore"
                component={PercentileAndScore}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveresult/difficultylevelwisescore"
                component={DifficultyLevelWiseScore}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveresult/timeperquestion"
                component={TimePerQuestion}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveresult/solutions"
                component={SolutionsObjectiveResult}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/objectiveresult/chapterwisescore"
                component={ChapterWiseScore}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/liveclass/pastliveclass"
                component={PastLiveClass}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/liveclass/upcomingliveclass"
                component={UpcomingLiveClass}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/notes/notessubjects"
                component={NotesSubjects}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/notes/noteschapters"
                component={NotesChapters}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/notes/noteslist"
                component={ListOfNotes}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/payment"
                component={Feeportal}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/payment/installment"
                component={Installment}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/payment/paynow"
                component={PayNow}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/profile"
                component={Profile}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/feesummary"
                component={Feesummary}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/changepassword"
                component={ChangePassword}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/feemodule/installmentpage"
                component={InstallmentPageStudent}
                value="Student"
              />

              <ProtectedRoute
                exact
                path="/student/feemodule/payment"
                component={PaymentStudent}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/feemodule/paymentstatus/:status"
                component={PaymentStatusStudent}
                value="Student"
              />
              <ProtectedRoute
                exact
                path="/student/Myschedule"
                component={Myschedule}
                value="Student"
              />
              {/* <ProtectedRoute exact path="/Parent" component={Parent} value="Parent" /> */}
              <ProtectedRoute
                exact
                path="/teacher"
                component={Teacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/addnotes"
                component={AddNotes}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/noteslist"
                component={NotesList}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/editnotes"
                component={EditNotes}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/doubtmodule"
                component={DoubtModuleTeacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/doubtmodule/alloteddoubtlist"
                component={ListOfAllotedDoubtsTeacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/doubtmodule/adddeu"
                component={AddDEUTeacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/doubtmodule/editalloteddoubt"
                component={EditAllotedDoubtTeacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/doubtmodule/solvedoubt"
                component={SolveDoubt}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/myschedule/MySchedule"
                component={MySchedule}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/pastclasses/MyPastLiveClasses"
                component={MyPastLiveClasses}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/ActionPage"
                component={ActionsPage}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/ChangePasswordTeacher"
                component={ChangePasswordTeacher}
                value="Teacher"
              />
              <ProtectedRoute
                exact
                path="/teacher/QuesionSearch"
                component={QuestionSearch}
                value="Teacher"
              />


              <ProtectedRoute
                exact
                path="/teacher/DPPAutomation/OptionsPage"
                component={OptionsPage}
                value="Teacher"
              />

              <ProtectedRoute
                exact
                path="/teacher/DPPAutomation/DppListPage"
                component={DppListPage}
                value="Teacher"
              />


              <ProtectedRoute
                exact
                path="/teacher/DPPAutomation/DppCreationPage"
                component={DppCreationPage}
                value="Teacher"
              />

              <ProtectedRoute
                exact
                path="/teacher/unverified-questions"
                component={UnverifiedQuestions}
                value="Teacher"
              />


              {/* TODO: Pass ques id or chapter id in url */}
              <ProtectedRoute
                exact
                path="/teacher/unverified-questions/:subjectID/:chapterID/"
                component={UnverifiedQuestionsChapter}
                value="Teacher"
              /> 

              {/* <ProtectedRoute
                exact
                path="/teacher/unverified-questions/chapter-question/similar-ques"
                component={CompareQuestions}
                value="Teacher"
              />  */}
              
              <ProtectedRoute
                exact
                path="/Support/CRM"
                component={CRM}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/upgradestudent/:student_id"
                component={UpgradeStudent}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/school/upgradestudent/:student_id"
                component={UpgradeStudentSchoolSide}
                value="School"
              />

              {/* User Permissions and Page Actions */}


              <ProtectedRoute
                exact
                path="/support/Users"
                component={Users}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/CreatNewUser"
                component={CreateNewUser}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/Permissions"
                component={Permissions}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/Roles"
                component={Roles}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/CreatNewRole"
                component={CreateNewRole}
                value="Support"
              />

              <ProtectedRoute
                exact
                path="/support/EditRolePermissons"
                component={EditRolePermissions}
                value="Support"
              />


              <Route exact path="*" component={ErrorPage} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
