import React,{useState,useEffect} from 'react'
import SideDrawer from './comp/SideDrawer';
import Multiselect from 'multiselect-react-dropdown';
import Swal from 'sweetalert2'
import { fontSize } from '@mui/system';
import Supportsidenav from '../supportsidenav';

export default function Permissions(props) {

    let data1 = localStorage.getItem("userdetail");
    data1 = JSON.parse(data1);
    const [userData, setUserData] = useState(data1);

    const [userPermissionList,setUserPermissionList]=useState([])
    const [schoolList,setSchoolList]=useState([])
    const [schoolListForAUser,setSchoolListForAUser]=useState([])
    const [selectedCenterIdList,setSelectedCenterIdList]=useState([])
    const [roleList,setRoleList]=useState([])
    const [reload,setReload]=useState(false)
    const [isLoading,setIsLoading]=useState(true)

    
    

    const getUserPermissions = async()=>{
        // console.log('tiken,',userData.token)
        try{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-role-page-action-for-user/?user_id=${props.history.location.state.user.user_id}`,{
                method:`GET`,
                headers: {
                    'Accept': "application/json",
                    "Content-Type": "application/json",
                    'Authorization': "Token " + userData.token,
                  },
                  // body:formdata
            })
            const data = await response.json()
            console.log(data,'per')
            let a = [];
            data.payload.roles.map((item,index)=>{
              if(item.is_enabled){
                let b = {'role_id':item.role_id}
                a.push(b)
              }
            })
            console.log('role list',a)
            setRoleList(a)
            setUserPermissionList(data.payload.roles)
            setIsLoading(false)
        }catch(err){
            console.log(err)
            Swal.fire({
              title: `Something went wrong`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
            })
        }
    }

    const getSchoolList = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/get-list-of-schools/`,{
            method:`GET`,
            headers: {
                'Accept': "application/json",
                "Content-Type": "application/json",
                'Authorization': "Token " + userData.token,
              },
              // body:formdata
        })
        const data = await response.json()
        console.log(data,'school list')
        setSchoolList(data)
  
      }catch(err){
          console.log(err)
          Swal.fire({
            title: `Something went wrong`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            
          })
      }
    }

    const getSchoolListForAUser = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-of-school-for-a-user/?user_id=${props.history.location.state.user.user_id}`,{
            method:`GET`,
            headers: {
                    "Content-Type": "application/json",
                    'Accept': "application/json",
                // "Content-Type": "multipart/form-data",
                'Authorization': "Token " + userData.token,
              },
              // body:formdata
        })
        const data = await response.json()
        console.log(data,'school user data')
        let a = []
        let c = []
        data.payload.schools.map((item,index)=>{
          let b = {'id':item.school_id,'name':item.school_name}
          let x = {'school_id':item.school_id}
          c.push(x)
          a.push(b)
        })
        console.log(c,'c')
        setSelectedCenterIdList(c)
        setSchoolListForAUser(a)
  
      }catch(err){
          console.log(err)
          Swal.fire({
            title: `Something went wrong`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            
          })
      }
    }


    

    const updateUser = async()=>{
      // console.log('tiken,',userData.token)
      try{
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-edit-support-user/`,{
              method:`POST`,
              headers: {
                  'Accept': "application/json",
                  // "Content-Type": "multipart/form-data",
                  'Content-Type' : "application/json",
                  'Authorization': "Token " + userData.token,
                },
                body:JSON.stringify({'user_school_role':[{'user_id':`${props.history.location.state.user.user_id}`,'mobile_number':`${props.history.location.state.user.mobile_number}`,'first_name':`${props.history.location.state.user.first_name}`,"email_id":`${props.history.location.state.user.email_id}`,"gender":`${props.history.location.state.user.gender}`,"roles":roleList,'schools':selectedCenterIdList}]})
          })
          const data = await response.json()
          console.log(data,'user updated')
          Swal.fire({
            title: `${data.message}`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            props.history.push({
              pathname: "/support/Users",
            })
          })
          setReload(!reload)


      }catch(err){
          console.log(err)
          Swal.fire({
            title: `Something went wrong`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            
          })
      }
  }

    useEffect(()=>{
        getUserPermissions()
        getSchoolList()
        getSchoolListForAUser()
        // console.log(props.history.location.state.user,'usre')
    },[reload])



    const onSelectCenter = (e) =>{
      // console.log(e,'center data')
      let a = []
      e.map((item,index)=>{
        let b = {'school_id':item.id}
        a.push(b)
      })
      setSelectedCenterIdList(a)
      // console.log(selectedCenterIdList,'center list')
    }

    const onRemoveCenter = (e) =>{
      // console.log(e,'center data')
      let a = []
      e.map((item,index)=>{
        let b = {'school_id':item.id}
        a.push(b)
      })
      setSelectedCenterIdList(a)
      // console.log(selectedCenterIdList,'center list')
    }

    const handleOnChange = (roleObj)=>{
      // console.log(roleObj,'roleobj')
      if(roleList.find((e)=>e.role_id==roleObj.role_id)){
        let filtered = roleList.filter((item)=>item.role_id!=roleObj.role_id)
        setRoleList(filtered)
        console.log(filtered,'rolelist filtered')
      }else{
        setRoleList([...roleList,{"role_id":roleObj.role_id}])
        console.log(roleList,'rolelist')
      }
    }

    const container = {
        display:'flex',
        width:'100%'
    }
    const currentPageContainer = {
        backgroundColor:'#E5E5E5',
        minHeight:'100vh',
        padding:20,
        width:'100%'
    }
    const headerBarStyle = {
        backgroundColor:'white',
        borderRadius:16,
        padding:20,
        justifyContent:'center',
        fontWeight:'600',
        fontSize:35,
        marginBottom:20
    }
    const headerRowStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:8,
        paddingLeft:25,
        justifyContent:'start',
        marginBottom:10,
        flexDirection:'row',
        display:'flex',
      }
      const headerItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const rowStyle = {
        backgroundColor:'white',
        borderRadius:8,
        padding:8,
        paddingLeft:25,
        justifyContent:'start',
        marginTop:8,
        flexDirection:'row',
        display:'flex'
      }
      const rowItemStyle = {
        width:'20%',
        justifyContent:'start',
        color:'#858585',
        fontSize:16
      }
      const subRowItemStyle = {
        backgroundColor:'#EDEDED',
        borderRadius:25,
        marginRight:10,
        paddingBlock:6,
        paddingInline:10
      }
      const statusAvailable = {
        backgroundColor:'#DDFFC2',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#53BC00'
      }
      const statusUnavailable = {
        backgroundColor:'#FFE8E8',
        paddingInline:20,
        borderRadius:107,
        paddingBlock:6,
        color:'#BC0000'
      }
      const basicDetailsInnerContainer = {
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'flex-start'
      }
      const permissionsListContainer = {
        backgroundColor:'white',
        borderRadius:8,
        padding:20,
        marginBottom:20
      }
      const actionListContainer = {
        backgroundColor:'#F4F7FE',
        borderRadius:8,
        padding:10,
        marginBottom:20
      }
      const pageListContainer = {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginLeft:8,
        flexWrap:'wrap'
      }
      const page = {
        marginLeft:8
      }
      const checkbox = {
        marginRight:10
      }
      const buttonStyle = {
        backgroundColor:'#012C63',
        fontSize:18,
        borderRadius:8,
        paddingBlock:10,
        paddingInline:16.5,
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        display:'flex'
      }

  return (
    <div className='row support_row' >
        <div className='col-lg-2 col-md-2 support_col' >
    <Supportsidenav />
        </div>
        <div className='col-lg-10 col-md-10 support_col' >
        <div style={currentPageContainer} >
            <div style={headerBarStyle} >
              Permissions
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Basic Details
             </h5>
            </div>

            <div style={permissionsListContainer} >
              <div style={basicDetailsInnerContainer} >
                
                <div style={{marginRight:20}} >
                  <p style={{fontSize:12}} >
                    User Name
                  </p>
                  <h4 style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37}} >
                    {props.history.location.state.user.first_name}
                  </h4>
                </div>

                {/* <div style={{marginRight:20,}} >
                  <p style={{fontSize:12}} >
                    Description
                  </p>
                  <h4 style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37}} >
                    
                  </h4>
                </div> */}


              </div>
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Associated Center
             </h5>
            </div>

            <div style={permissionsListContainer} >
              <div style={basicDetailsInnerContainer} >

              <div style={{marginRight:20}} >
                  <p style={{fontSize:12}} >
                    Assign Center
                  </p>
                 
                  {/* <form>
                    <label>
                    <select onChange={selectCenterHandler} style={{borderColor:'#858585',borderWidth:0.5,border:'solid',padding:8,borderRadius:8,width:200,minHeight:37,display:'flex',flexDirection:'row',color:'#858585'}} >            
                        <option value="">Select Center</option>
                        {schoolList.map((item,index)=>{
                          return(
                            <option value={item.name} id={item.id} >{item.name}</option>
                          )
                        })
                          }
                    </select>
                    </label>
                  </form> */}

                  <Multiselect
                  options={schoolList} // Options to display in the dropdown
                  selectedValues={schoolListForAUser} // Preselected value to persist in dropdown
                  onSelect={(item)=>onSelectCenter(item)} // Function will trigger on select event
                  onRemove={(item)=>onRemoveCenter(item)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  />
             
              </div>

              </div>
            </div>

            <div style={headerRowStyle} >
             <h5 style={headerItemStyle} >
              Edit Permissions
             </h5>
            </div>

            <div style={permissionsListContainer} >
            {isLoading?
              <div style={actionListContainer} >
                ...Loading
                </div>:

              userPermissionList.map((item,index)=>{
                return(
              <div key={index} style={actionListContainer} >
                 <input
          type="checkbox"
          defaultChecked={item.is_enabled}
          style={checkbox}
          // checked={isChecked}
          onChange={()=>handleOnChange(item)}
        />
                  {item.role}
                  {/* <div style={pageListContainer} >
                    Pages
                  {item.pages.map((itm,i)=>{
                    return(
                      <div key={i} style={page} >
                        <input
          type="checkbox"
         
          checked={item.is_enabled}
          style={checkbox}
          // checked={isChecked}
          // onChange={handleOnChange}
        />
                        {itm.page}
                        <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'column',alignItems:'flex-start'}} >
                        {itm.actions.map((iitm,indxx)=>{
                          return(
                             <div key={indxx} style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}} > 
                              {iitm.action}&nbsp; 
                              <input
          type="checkbox"
         
          checked={item.is_enabled}
          style={checkbox}
          // checked={isChecked}
          // onChange={handleOnChange}
        />
        </div>
        )
      })}
      </div>
      
                        </div>
                    )
                  })}
                 
                  </div> */}
              </div>
                )
              })
              }


            </div>


            <div style={headerBarStyle} >
            <button
            onClick={updateUser}
              style={{...buttonStyle,width:143}} >
                Update User
              </button>
            </div>

        </div>

    </div>
    </div>
  )
}
