import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../config.json";
import parse from "html-react-parser";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReactLoading from "react-loading";
import dateFormat from "dateformat";
import ReactPlayer from "react-player";
import Teachernav from "./teachernav";
const QuestionSearch = () => {
  let data = localStorage.getItem("userdetail");
  data = JSON.parse(data);
  const [Question, SetQuestion] = useState([]);
  const [token, setToken] = useState(data.token);
  const [cls, Setclass] = useState([]);
  const [sub, Setsub] = useState([]);
  const [sec, Setsec] = useState([]);
  const [selectedclass, Setselectedclass] = useState("");
  const [selectedsub, Setselectedsub] = useState("");
  const [selectedchp, Setselectedchp] = useState("");
  const [verified, setVerified] = useState("");
  const [loading, Setloading] = useState(false);
  const [chp, Setchp] = useState([]);
  const [ques_id, setQues] = useState([]);
  const [question_byId, setQuestionbyId] = useState("");
  const container = React.useRef(null);
  const [url_link, Seturl] = useState("");
  const [chapterId, setChapterId] = useState(null);
  const [lang, setLang] = useState([]);
  const [questionLanguage, setQuestionLanguage] = useState("");
  const [questionDifficultyLevel, setQuestionDifficultyLevel] = useState("");
  const [questionConceptId, setQuestionConceptId] = useState("");

  const [videoSol, setVideoSol] = useState(false);
  const pdfExportComponent = React.useRef(null);

  const level = [1, 2, 3, 4, 5];

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  useEffect(() => {
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    };
    Setloading(true);
    axios
      .get(process.env.REACT_APP_BASE_URL + "support/get-class/", req)
      .then((response) => {
        console.log(response.data);
        const cls = response.data;
        Setclass(cls);
        Setloading(false);
      })
      .catch((error) => {
        alert("Server Error");
      });
    getLanguage();
  }, []);

  const getLanguage = async () => {
    var requestOptions = {
      redirect: "follow",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `support/get-languages/`,
      requestOptions
    );
    const D = await response.json();
    console.log(D, "languages");
    setLang(D);
  };

  function select_class(e) {
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    };
    Setloading(true);
    let class_variable = e.target.value;
    console.log(class_variable, "class var");
    Setselectedclass(class_variable);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "support/get-subject-class-binding/?class_id=" +
          class_variable,
        req
      ) //Subject API
      .then((response) => {
        console.log(response.data, "class ses");
        const sub = response.data;
        console.log(sub);
        Setloading(false);
        Setsub(sub);
      })
      .catch((error) => {
        alert("Server Error");
      });
  }
  function select_subject(e) {
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    };
    let Subject_variable = e.target.value;
    console.log(Subject_variable, "sub var");
    Setselectedsub(Subject_variable);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "support/get-chapters/?subject=" +
          Subject_variable,
        req
      )
      .then((response) => {
        console.log(response.data, "subbb");
        const Chapter_data = response.data;
        console.log(Chapter_data);
        Setloading(false);
        Setchp(Chapter_data);
      });
  }

  const select_language = (e) => {
    let languageId = e.target.value;
    setQuestionLanguage(languageId);
    console.log(languageId, "lang id");
  };

  const getQuestionBank = (chapterId) => {
    Setloading(true);
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    };
    // console.log(chapterId,'chaterId')
    // console.log(videoSol,'solll')
    if (videoSol == true) {
      axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `student/get-objective-exam-questions-per-chapter/?chapter=${chapterId}&language=${questionLanguage}&difficulty_level=${questionDifficultyLevel}&question_type=${questionConceptId}&solution_video=true&verified=${verified}`,
          req
        ) // Questions API
        .then((response) => {
          const lang = response.data;
          console.log(lang);
          SetQuestion(lang);
          Setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          // `${Config.SERVER_URL}
          process.env.REACT_APP_BASE_URL +
            `student/get-objective-exam-questions-per-chapter/?chapter=${chapterId}&language=${questionLanguage}&difficulty_level=${questionDifficultyLevel}&question_type=${questionConceptId}&verified=${verified}`,
          req
        ) // Questions API
        .then((response) => {
          const lang = response.data;
          console.log(lang);
          SetQuestion(lang);
          Setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const select_difficulty = (e) => {
    let levelid = e.target.value;
    setQuestionDifficultyLevel(levelid);
    console.log(levelid, "lang id");
  };

  const select_concept = (e) => {
    let levelid = e.target.value;
    setQuestionConceptId(levelid);
    console.log(levelid, "Concept Id");
  };

  function select_chapter(e) {
    // Setloading(true)
    // let req = {
    //     redirect: 'follow',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Token ' + token
    //     },
    // };
    let Chapter_variable = e.target.value;
    console.log(Chapter_variable);
    setChapterId(Chapter_variable);
  }
  function EditQuestion(
    id,
    question,
    option1,
    option2,
    option3,
    option4,
    solution
  ) {
    window.open(`/support/objectivemodule/ListOfQuestion-Exam/edit/${id}`);
  }
  function SearchQuestion() {
    console.log("in search ques by id");
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    };
    console.log(ques_id);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `student/get-objective-question-by-id?id=${ques_id}`,
        req
      )
      .then((data) => {
        console.log(data);
        setQuestionbyId(data.data);
        // SetQuestion(data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const multiQues = () => {
    setQuestionbyId("");
    getQuestionBank(chapterId);
  };

  const singleQue = () => {
    setQuestionbyId("");
    SearchQuestion();
  };

  const onChangeCheckbox = () => {
    // console.log(videoSol,'video sol')
    setVideoSol(!videoSol);
  };

  function viewRecording(url) {
    Seturl(url);
  }
  function f1(e) {
    // if(e.target.value===''){
    //     console.log('set krale')
    // }
    setVerified(e.target.value);
    console.log(e.target.value);
  }
  return (
    <div>
      <div
        class="modal fade"
        id="videoplayer"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Question Solution Video
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={() => Seturl(null)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {url_link ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                    controls
                    url={url_link}
                    width="100%"
                  />
                </div>
              ) : (
                <div>
                  <h2>Question Have No Solution Video</h2>
                </div>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => Seturl(null)}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Teachernav />
      <div className="container">
        <div></div>
        <div>
          {loading ? <ReactLoading type="cylon" color="#09AEE5" /> : ""}
          <div style={{ margin: "20px" }}>
            <h2>List of Questions</h2>
            <p>
              Total Number of Questions :{" "}
              {question_byId.length != 0 ? "1" : Question.length}
            </p>
            {Question.length === 0 ? (
              ""
            ) : (
              <button
                className="btn btn-success"
                onClick={() => {
                  Question.length < 100
                    ? exportPDFWithComponent()
                    : alert("generate pdf limit (100) exceded");
                }}
              >
                GENERATE PDF
              </button>
            )}
            <div className="row">
              <div className="col-lg-6">
                <label>Select class</label>
                <select class="form-control" onChange={select_class} required>
                  <option value="0">Select</option>
                  {cls.map((item) => (
                    <option value={item.id}>{item.class_name}</option>
                  ))}
                  ;
                </select>
              </div>
              <div className="col-lg-6">
                <label>Subject</label>
                <select class="form-control" onChange={select_subject} required>
                  <option value="0">Select subject</option>
                  {sub.map((item) => (
                    <option value={item.id}>{item.subject_name}</option>
                  ))}
                  ;
                </select>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <label> Select chapter</label>
                  <select class="form-control" onChange={select_chapter}>
                    <option value="">Select</option>
                    {chp.map((item) => (
                      <option value={item.id}>{item.chapter_name}</option>
                    ))}
                    ;
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label> Select Difficulty Level</label>
                  <select class="form-control" onChange={select_difficulty}>
                    <option value="">Select</option>
                    {level.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                    ;
                  </select>
                </div>
                <br />
                <div className="col-lg-6">
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div>
                      <input
                        type="radio"
                        name="radiobtn"
                        value={""}
                        onChange={f1}
                      />
                      <lable style={{ marginLeft: "10px" }}>All</lable>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="radiobtn"
                        value={"true"}
                        onChange={f1}
                      />
                      <lable style={{ marginLeft: "10px" }}>Verified</lable>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="radiobtn"
                        value={"false"}
                        onChange={f1}
                      />
                      <lable style={{ marginLeft: "10px" }}>UnVerified</lable>
                    </div>
                  </div>
                  <div style={{ marginTop: "27px" }}>
                    <input
                      type="checkbox"
                      value={videoSol}
                      onChange={onChangeCheckbox}
                    />
                    <label style={{ marginLeft: 5 }}>
                      Video Solution compulsary{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label> Select Concept Id </label>
                  <select class="form-control" onChange={select_concept}>
                    <option value="">Select</option>
                    {["Memory", "Analytical", "Conceptual"].map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                    ;
                  </select>
                </div>
                <br />
                <button
                  className="btn btn-success"
                  onClick={() =>
                    // SearchQuestion
                    chapterId != null && ques_id == ""
                      ? multiQues()
                      : ques_id != ""
                      ? singleQue()
                      : alert("Select a Chapter or Enter a Quetion Id")
                  }
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label>Enter Question Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Question Id"
                  value={ques_id}
                  onChange={(e) => setQues(e.target.value)}
                />
                {/* <br /> */}
              </div>
              <div className="col-lg-6">
                {/* <div className='col-lg-6'>
                                  test apear in
                              </div> */}
              </div>
            </div>
            {question_byId ? (
              <div>
                <div className="pdf_con">
                  <br />
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      border: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    <div style={{ fontSize: "20px" }}>
                      <label>Question ID:</label>
                      <lable style={{ margin: "10px" }}>
                        {question_byId.id}
                      </lable>
                    </div>
                    <div>
                      <label>Question Text</label>
                      <label>{parse(question_byId.question_text)}</label>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        {question_byId.correct_option === "1" ? (
                          <div
                            style={{
                              border: "3px solid green",
                              padding: "5px",
                            }}
                          >
                            <label>Option 1</label>
                            {parse(question_byId.option1_text)}
                          </div>
                        ) : (
                          <div
                            style={{ border: "3px solid red", padding: "5px" }}
                          >
                            <label>Option 1</label>
                            {parse(question_byId.option1_text)}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        {question_byId.correct_option === "2" ? (
                          <div
                            style={{
                              border: "3px solid green",
                              padding: "5px",
                            }}
                          >
                            <label>Option 2</label>
                            {parse(question_byId.option2_text)}
                          </div>
                        ) : (
                          <div
                            style={{ border: "3px solid red", padding: "5px" }}
                          >
                            <label>Option 2</label>
                            {parse(question_byId.option2_text)}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-6">
                        {question_byId.correct_option === "3" ? (
                          <div
                            style={{
                              border: "3px solid green",
                              padding: "5px",
                            }}
                          >
                            <label>Option 3</label>
                            {parse(question_byId.option3_text)}
                          </div>
                        ) : (
                          <div
                            style={{ border: "3px solid red", padding: "5px" }}
                          >
                            <label>Option 3</label>
                            {parse(question_byId.option3_text)}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        {question_byId.correct_option === "4" ? (
                          <div
                            style={{
                              border: "3px solid green",
                              padding: "5px",
                            }}
                          >
                            <label>Option 4</label>
                            {parse(question_byId.option4_text)}
                          </div>
                        ) : (
                          <div
                            style={{ border: "3px solid red", padding: "5px" }}
                          >
                            <label>Option 4</label>
                            {parse(question_byId.option4_text)}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <label>Solution Text</label>
                    <div>{parse(question_byId.solution_text)}</div>
                    <br />
                    <label>Difficulty : {question_byId.difficulty_level}</label>
                    <br />
                    <label>Concept Id : {question_byId.level_i}</label>
                    <br />
                    <label>
                      Created on : {dateFormat(question_byId.created_on)}
                    </label>
                    <br />
                    <br />
                    <label>Chapter Name : {question_byId.chapter_name}</label>
                    <br />
                    <br />
                    <label>Tags : </label>
                    {question_byId.tags.map((item) => (
                      <div>{item.tag_name}</div>
                    ))}
                    <br />
                    <br />
                    {question_byId.solution_video != null ? (
                      <button
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#videoplayer"
                        onClick={() =>
                          viewRecording(question_byId.solution_video)
                        }
                        style={{ margin: "20px" }}
                      >
                        View Recording
                      </button>
                    ) : null}
                    {/* <button
                      className="btn btn-primary"
                      onClick={() => EditQuestion(question_byId.id)}
                    >
                      Edit Question
                    </button> */}
                  </div>
                  <br />
                </div>
              </div>
            ) : (
              <div>
                {Question.length === 0 ? (
                  "No Questions Found"
                ) : (
                  <PDFExport
                    paperSize="A3"
                    margin="0.5cm"
                    ref={pdfExportComponent}
                    fileName="Question Details"
                  >
                    {Question.map((item) => (
                      <div className="pdf_con">
                        <br />
                        <div
                          style={{
                            width: "100%",
                            height: "auto",
                            border: "1px solid black",
                            padding: "10px",
                          }}
                        >
                          <div style={{ fontSize: "20px" }}>
                            <label>Question ID:</label>
                            <lable style={{ margin: "10px" }}>{item.id}</lable>
                          </div>
                          <div>
                            <label>Question Text</label>
                            <label>{parse(item.question_text)}</label>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              {item.correct_option === "1" ? (
                                <div
                                  style={{
                                    border: "3px solid green",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 1</label>
                                  {parse(item.option1_text)}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: "3px solid red",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 1</label>
                                  {parse(item.option1_text)}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              {item.correct_option === "2" ? (
                                <div
                                  style={{
                                    border: "3px solid green",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 2</label>
                                  {parse(item.option2_text)}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: "3px solid red",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 2</label>
                                  {parse(item.option2_text)}
                                </div>
                              )}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-lg-6">
                              {item.correct_option === "3" ? (
                                <div
                                  style={{
                                    border: "3px solid green",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 3</label>
                                  {parse(item.option3_text)}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: "3px solid red",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 3</label>
                                  {parse(item.option3_text)}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              {item.correct_option === "4" ? (
                                <div
                                  style={{
                                    border: "3px solid green",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 4</label>
                                  {parse(item.option4_text)}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: "3px solid red",
                                    padding: "5px",
                                  }}
                                >
                                  <label>Option 4</label>
                                  {parse(item.option4_text)}
                                </div>
                              )}
                            </div>
                          </div>
                          <br />
                          <label>Solution Text</label>
                          <div>{parse(item.solution_text)}</div>
                          <br />
                          <label>Difficulty : {item.difficulty_level}</label>
                          <br />
                          <label>Concept Id : {item.level_i}</label>
                          <br />
                          <label>
                            Created on : {dateFormat(item.created_on)}
                          </label>
                          <br />
                          <br />
                          <label>Chapter Name : {item.chapter_name}</label>
                          <br />
                          <br />
                          <label>Tags : </label>
                          {item.tags.map((itemm) => (
                            <div>{itemm.tag_name}</div>
                          ))}
                          <br />
                          <br />
                          {item.solution_video != null ? (
                            <button
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#videoplayer"
                              onClick={() => viewRecording(item.solution_video)}
                              style={{ margin: "10px" }}
                            >
                              View Recording
                            </button>
                          ) : null}
                          {/* <button
                            className="btn btn-primary"
                            onClick={() =>
                              EditQuestion(
                                item.id,
                                item.question_text,
                                item.option1_text,
                                item.option2_text,
                                item.option3_text,
                                item.option4_text,
                                item.solution_text
                              )
                            }
                          >
                            Edit Question
                          </button> */}
                        </div>
                        <br />
                      </div>
                    ))}
                  </PDFExport>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionSearch;
