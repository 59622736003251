import { Component } from "react";
import { NavLink } from "react-router-dom";
import "../support/supportsidenav.css";
import axios from "axios";
import Config from "../config.json";

class Supportsidenav extends Component {
  constructor() {
    super();
    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);
    this.state = {
      token: data.token,
      modules: "",
      username: data.username,
      PerMission_data: [],
      urls: [],
      requestOptions: null,
    };
    let req = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    };
    this.setState({
      requestOptions: req,
    });
  }
  Logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  componentDidMount = async () => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `support/get-support-user-permissions/?username=${this.state.username}`,
        this.state.requestOptions
      )
      .then((data) => {
        console.log(data.data);
        this.setState({
          PerMission_data: data.data,
        });
        this.state.PerMission_data.map((item, index) => {
          this.state.urls.push(item.support_url_assoc.url);
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.history.push("/error");
      });
    var formdata = new FormData();
    this.setState({ isloading: true });
    formdata.append("page_name", "Support-Dashboard");
    var requestOptions1 = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        Authorization: "Token " + this.state.token,
      },
    };
    fetch(
      process.env.REACT_APP_BASE_URL + "users/post-analytics/",
      requestOptions1
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        this.props.history.push("/error");
      });
    var requestOptions = {
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_BASE_URL + "support/get-support-dashboard/",
        requestOptions
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          modules: response.data,
        });
      })
      .catch((error) => {
        this.props.history.push("/error");
      });
  };
  render() {
    return (
      <div>
        <div className="Nav_side">
          <div className="side_logo">
            <svg
              width="175"
              height="49"
              viewBox="0 0 175 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.5863 15.0682V35.32L20.267 46.1013L1.94781 35.32V15.0682L0 13.5791V36.5101L20.2698 48.3065L40.5403 36.5101V13.5819L38.5863 15.0682Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.6287 14.3567V33.7168L20.2647 42.1131L5.90076 33.7168V14.3567L19.4112 22.2784L20.2633 22.8148L21.1154 22.2784L34.6287 14.3567ZM36.2725 11.4971L20.264 20.9086L4.25342 11.4971V34.6674L20.2619 44.0193L36.2704 34.6674L36.2725 11.4971Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2633 18.2874L22.2721 17.0959C22.0581 16.7548 21.7622 16.4726 21.4114 16.275C21.0606 16.0773 20.6659 15.9705 20.2633 15.9642C19.8581 15.9577 19.4586 16.0591 19.1056 16.258C18.7525 16.4569 18.4588 16.7461 18.2544 17.0959L20.2633 18.2874Z"
                fill="#E97A26"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.9074 15.1308C15.4861 14.3341 16.246 13.6864 17.1244 13.2411C18.0027 12.7959 18.9744 12.566 19.9591 12.5703C20.9948 12.5692 22.0147 12.8242 22.9279 13.3127C23.8412 13.8012 24.6194 14.5081 25.1933 15.3702L26.8372 14.4175C26.1055 13.2865 25.1069 12.3529 23.9294 11.6987C22.7519 11.0446 21.4316 10.69 20.0848 10.6663C18.738 10.6426 17.4061 10.9504 16.2063 11.5627C15.0065 12.175 13.9756 13.0729 13.2046 14.1774L14.9074 15.1308Z"
                fill="#E97A26"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5844 12.629C11.6135 10.9952 13.0424 9.65099 14.7359 8.72344C16.4294 7.79588 18.3315 7.31566 20.2624 7.32819C22.1909 7.32824 24.0884 7.81384 25.7799 8.74023C27.4714 9.66662 28.9024 11.004 29.941 12.629L31.707 11.6166C30.5022 9.68447 28.8253 8.09097 26.8343 6.98621C24.8434 5.88146 22.6039 5.30184 20.3269 5.30197C18.0475 5.28906 15.8032 5.86323 13.81 6.96918C11.8169 8.07514 10.142 9.67564 8.94678 11.6166L10.5844 12.629Z"
                fill="#E97A26"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.89729 9.82863C7.42835 7.40345 9.54854 5.40541 12.0602 4.02076C14.5718 2.63611 17.3932 1.90994 20.2612 1.90994C23.1293 1.90994 25.9506 2.63611 28.4623 4.02076C30.9739 5.40541 33.0941 7.40345 34.6252 9.82863L36.2691 8.8759C34.5741 6.15872 32.215 3.91806 29.4141 2.36527C26.6133 0.812484 23.463 -0.0012632 20.2605 0.000781846C17.0531 -0.0286515 13.8926 0.773414 11.0874 2.3288C8.28206 3.88419 5.92759 6.13983 4.25342 8.8759L5.89729 9.82863Z"
                fill="#E97A26"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M68.3531 30.0807V34.7299H46.5005V31.0341L60.2573 14.8924H46.8044V10.2432H67.866V13.9362L54.1703 30.0807H68.3531Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M72.0054 12.4502C71.6991 12.1831 71.4537 11.8536 71.2855 11.4837C71.1173 11.1139 71.0303 10.7123 71.0303 10.306C71.0303 9.89969 71.1173 9.4981 71.2855 9.12825C71.4537 8.75839 71.6991 8.42886 72.0054 8.16181C72.6909 7.55173 73.5864 7.23134 74.5034 7.26805C75.4102 7.2291 76.2995 7.52554 77.0015 8.10074C77.3152 8.3474 77.5665 8.66429 77.7352 9.02588C77.904 9.38747 77.9854 9.78365 77.973 10.1825C77.987 10.6079 77.9073 11.0313 77.7394 11.4225C77.5715 11.8136 77.3196 12.1631 77.0015 12.446C76.3156 13.0555 75.4203 13.3755 74.5034 13.3391C73.594 13.3307 72.714 13.0161 72.0054 12.446V12.4502ZM71.7 15.9052H77.3006V34.7268H71.7021L71.7 15.9052Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M99.8847 17.7508C101.342 19.1206 102.075 21.2058 102.075 23.9454V34.6663H96.4748V24.7198C96.5509 23.5283 96.2072 22.3478 95.5033 21.3835C95.1355 21.0007 94.6896 20.7016 94.196 20.5063C93.7024 20.311 93.1725 20.2242 92.6423 20.2517C92.029 20.2231 91.4161 20.3191 90.8408 20.5337C90.2655 20.7484 89.7397 21.0773 89.2949 21.5007C88.8385 22.0188 88.4902 22.6228 88.2705 23.2774C88.0509 23.932 87.9644 24.6239 88.0161 25.3124V34.6045H82.4766V15.785H87.7718V17.9889C88.5332 17.174 89.4697 16.5426 90.5106 16.1424C91.6582 15.7079 92.8753 15.486 94.1023 15.4873C96.2453 15.4909 98.3088 16.2987 99.8847 17.7508Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M150.468 8.81636V34.727H145.173V32.5821C143.773 34.1906 141.825 35.0247 139.146 35.0247C137.43 35.0339 135.738 34.625 134.215 33.8332C132.76 33.0747 131.571 31.8905 130.807 30.4386C129.967 28.8618 129.528 27.1028 129.528 25.3165C129.528 23.5302 129.967 21.7712 130.807 20.1944C131.623 18.7809 132.799 17.6091 134.215 16.7984C135.735 15.9998 137.429 15.5904 139.146 15.607C140.207 15.5498 141.267 15.7226 142.254 16.1134C143.242 16.5042 144.133 17.1038 144.867 17.8712V8.75391H150.468V8.81636ZM143.651 29.0681C144.125 28.5566 144.492 27.9563 144.733 27.3018C144.973 26.6473 145.081 25.9516 145.051 25.2551C145.101 23.8503 144.599 22.4817 143.651 21.4434C142.704 20.5268 141.438 20.0144 140.12 20.0144C138.802 20.0144 137.536 20.5268 136.589 21.4434C135.686 22.5082 135.19 23.8593 135.19 25.2558C135.19 26.6523 135.686 28.0034 136.589 29.0681C137.047 29.5378 137.597 29.9073 138.204 30.1533C138.812 30.3994 139.464 30.5166 140.12 30.4976C140.774 30.511 141.425 30.3913 142.032 30.1457C142.639 29.9001 143.19 29.5337 143.651 29.0688V29.0681Z"
                fill="#E97A26"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M175 15.9053V34.7269H169.703V32.4634C168.97 33.2827 168.052 33.9157 167.026 34.3099C165.982 34.7386 164.866 34.961 163.738 34.9649C161.526 35.0678 159.362 34.2978 157.713 32.8207C156.191 31.3913 155.521 29.3061 155.521 26.5061V15.9053H161.121V25.7324C161.121 28.7704 162.4 30.2588 165.017 30.2588C165.611 30.2819 166.204 30.183 166.759 29.9681C167.314 29.7533 167.819 29.4272 168.243 29.0098C169.119 27.9406 169.554 26.5781 169.46 25.1995V15.9053H175Z"
                fill="#E97A26"
              />
              <path
                d="M126.364 10.2461H107.494V14.7732H126.364V10.2461Z"
                fill="#E97A26"
              />
              <path
                d="M124.842 20.1338H107.494V24.5415H124.842V20.1338Z"
                fill="#E97A26"
              />
              <path
                d="M126.851 30.1396H107.494V34.6667H126.851V30.1396Z"
                fill="#E97A26"
              />
            </svg>
          </div>
          <div>
            {this.state.urls ? (
              <div className="Nav_inner_con">
                <NavLink to="/">
                  <div className="Nav_links">
                    <div className="Nav_links_con">
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>
                        <span className="Nav_heading">Dashboard</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/support/uploadvideo">
                  <div className="Nav_links">
                    <div className="Nav_links_con">
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>
                        <span className="Nav_heading">Add a Video</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
                {this.state.urls.some((e) => e === "Doubt Module") ? (
                  <NavLink to="/support/doubtmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Doubt Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Tag Module") ? (
                  <NavLink to="/support/tagmodule/tagmoduleland">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Tag Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) =>
                  e === "School Module" ? (
                    <NavLink to="/support/schoolmodule">
                      <div className="Nav_links">
                        <div className="Nav_links_con">
                          <div>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>
                            <span className="Nav_heading">School Module</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  ) : (
                    ""
                  )
                )}
                <NavLink to="/support/AddCity">
                  <div className="Nav_links">
                    <div className="Nav_links_con">
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>
                        <span className="Nav_heading">Add a City</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
                {this.state.urls.some((e) => e === "Section Module") ? (
                  <NavLink to="/support/sectionmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Section Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}

                {this.state.urls.some((e) => e === "Payment Management") ? (
                  <NavLink to="/support/PromoCode/">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">
                            Payment Management
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Objective Module") ? (
                  <NavLink to="/support/objectivemodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Objective Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Live Class Module") ? (
                  <NavLink to="/support/Schedule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Live Class</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}

                {this.state.urls.some((e) => e === "Data Entry Module") ? (
                  <NavLink to="/support/dataentryusermodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Data Entry</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Student Module") ? (
                  <NavLink to="/support/studentmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Student Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Notes Module") ? (
                  <NavLink to="/support/notesmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Notes Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Chapter Module") ? (
                  <NavLink to="/support/chaptermodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Chapter Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Subject Module") ? (
                  <NavLink to="/support/subjectmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Subject Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some((e) => e === "Class Module") ? (
                  <NavLink to="/support/classmodule">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">Class Module</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}
                {this.state.urls.some(
                  (e) => e === "Create Credentials Module"
                ) ? (
                  <NavLink to="/support/CreateUserHome">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">
                            Create Credentials Module
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  ""
                )}

                  <NavLink to="/support/Users">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">
                            Users
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>

                  <NavLink to="/support/Roles">
                    <div className="Nav_links">
                      <div className="Nav_links_con">
                        <div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>
                          <span className="Nav_heading">
                            Roles
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>

                <div
                  className="Nav_links"
                  onClick={this.Logout}
                  style={{ cursor: "pointer" }}
                >
                  <div className="Nav_links_con">
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.7433 8.3796L10.8833 0.519599C10.7186 0.354871 10.5231 0.224197 10.308 0.135043C10.0928 0.0458881 9.86216 0 9.62926 0C9.39635 0 9.16573 0.0458881 8.95056 0.135043C8.7354 0.224197 8.53991 0.354871 8.37526 0.519599L0.520258 8.3746L0.512258 8.3826C0.270833 8.62676 0.1055 8.93577 0.0363385 9.2721C-0.0328225 9.60843 -0.00283512 9.9576 0.122659 10.2772C0.248152 10.5968 0.463752 10.8731 0.743273 11.0725C1.02279 11.272 1.35421 11.3859 1.69726 11.4006H1.75226H2.06526V17.1906C2.06579 17.741 2.28468 18.2688 2.67389 18.658C3.0631 19.0472 3.59083 19.2661 4.14126 19.2666H7.21126C7.36084 19.2666 7.5043 19.2072 7.61007 19.1014C7.71584 18.9956 7.77526 18.8522 7.77526 18.7026V14.1676C7.77552 13.9165 7.87538 13.6758 8.05292 13.4983C8.23046 13.3207 8.47118 13.2209 8.72226 13.2206H10.5363C10.7873 13.2209 11.0281 13.3207 11.2056 13.4983C11.3831 13.6758 11.483 13.9165 11.4833 14.1676V18.7006C11.4833 18.8502 11.5427 18.9936 11.6484 19.0994C11.7542 19.2052 11.8977 19.2646 12.0473 19.2646H15.1223C15.6722 19.2638 16.1993 19.0451 16.5883 18.6563C16.9772 18.2676 17.1962 17.7405 17.1973 17.1906V11.4066H17.4873C17.8379 11.4064 18.1806 11.3024 18.4721 11.1076C18.7636 10.9127 18.9909 10.6359 19.1252 10.312C19.2595 9.98813 19.2948 9.63171 19.2266 9.28777C19.1585 8.94384 18.9899 8.62781 18.7423 8.3796H18.7433Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>
                      <span className="Nav_heading">LogOut</span>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            ) : (
              "Loading..........."
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Supportsidenav;
