import React, {useEffect,useState} from 'react'
import Swal from 'sweetalert2'
import SideDrawer from './comp/SideDrawer';
import Box from '@mui/material/Box';
import Supportsidenav from '../supportsidenav';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OutlinedInput from '@mui/material/OutlinedInput';
// import "../supportnav.css";

// const options = [
//   'None',
//   'Atria',
//   'Callisto',
//   'Dione',
//   'Ganymede',
//   'Hangouts Call',
//   'Luna',
//   'Oberon',
//   'Phobos',
//   'Pyxis',
//   'Sedna',
//   'Titania',
//   'Triton',
//   'Umbriel',
// ];

const ITEM_HEIGHT = 48;

function Users(props) {

  let data1 = localStorage.getItem("userdetail");
  data1 = JSON.parse(data1);
  const [userData, setUserData] = useState(data1);
  const [userList,setUserList]= useState([])
  const [userStatus,setUserStatus]= useState([])
  const [isLoading,setIsLoading]=useState(true)
  const [reload,setReload]=useState(false)

  const [filteredList,setFilteredList]=useState([])
  const [search,setSearch]=useState('')

  const [selectedItem,setSelectedItem]=useState()
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentNumber, setCurrentNumber] = useState(null);
  const handleOpenMenu = (event, number) => {
    setAnchorEl(event.currentTarget);
    setCurrentNumber(number);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentNumber(null);
  };

  
  const getUsersList = async() =>{
    setIsLoading(true)
    try{
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-of-user/`,{
        method:'GET',
        headers: {
          'Accept': "application/json",
          "Content-Type": "application/json",
          'Authorization': "Token " + userData.token,
        }
      })
        const D = await response.json()
        console.log(D.payload.users,'user')
        setUserList(D.payload.users)
        setIsLoading(false)
    }catch(err){
      console.log(err.message)
      console.log(err,'errrrr')
      // Swal.fire(
      //   'Sorry?',
      //   'something went wrong',
      //   'question'
      // )
      Swal.fire({
        title: `${err.message}`,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setReload(!reload)
        } 
      })
      
    }
  }

  const getUserStatusList = async() =>{
    setIsLoading(true)
    try{
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/get-user-count/`,{
        method:'GET',
        headers: {
          'Accept': "application/json",
          "Content-Type": "application/json",
          'Authorization': "Token " + userData.token,
        }
      })
        const D = await response.json()
        console.log(D,'user status')
        setUserStatus(D.payload)
        setIsLoading(false)
    }catch(err){
      console.log(err.message)
      console.log(err,'errrrr')
      // Swal.fire(
      //   'Sorry?',
      //   'something went wrong',
      //   'question'
      // )
      Swal.fire({
        title: `${err.message}`,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setReload(!reload)
        } 
      })
      
    }
  }

  const changeStatus = async(user_id,status)=>{
    console.log(user_id,'user id:',userData.token)
    try{
      let formdata = new FormData()
      formdata.append('user_id',user_id)
      formdata.append('status',!status)

      const response = await fetch (`${process.env.REACT_APP_BASE_URL}users/activate-deactivate-user/`,{
        method:'PUT',
        headers: {
          // 'Accept': "application/json",
          // "Content-Type": "application/json",
          'Authorization': "Token " + userData.token,
        },
      body:formdata
      })

      const D = await response.json()
      console.log(D,'status update')
      Swal.fire({
        title: `${D.message}`,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
      setReload(!reload)
    }catch(err){
      console.log(err)
      Swal.fire({
        title: `${err.message}`,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        
      })
    }
  }

  const filterList = (txt) => {
    let filteredName = userList.filter(el =>el.first_name.toLowerCase().indexOf(txt.toLowerCase()) !== -1)
    let filteredNumber = userList.filter(el =>el.mobile_number.toLowerCase().indexOf(txt.toLowerCase()) !== -1)

    setSearch(txt)
    filteredName = filteredName.concat(filteredNumber)
    setFilteredList(filteredName)
    // console.log(filteredName,'filtered')
  }

  useEffect(()=>{
    getUserStatusList()
    getUsersList()
  },[reload])

  const container = {
    display:'flex',
    width:'100%'
}
const currentPageContainer = {
    backgroundColor:'#E5E5E5',
    minHeight:'100vh',
    padding:20,
    width:'100%'
}
const headerBarStyle = {
  backgroundColor:'white',
  borderRadius:16,
  padding:20,
  justifyContent:'space-between',
  fontWeight:'600',
  fontSize:35,
  display:'flex',
  flexDirection:'row'
}
  const headerRowStyle = {
    backgroundColor:'#EDEDED',
    borderRadius:8,
    padding:8,
    paddingLeft:25,
    justifyContent:'start',
    marginTop:8,
    marginBottom:10,
    flexDirection:'row',
    display:'flex',
  }
  const headerItemStyle = {
    width:'18.5%',
    justifyContent:'start',
    color:'#858585'
  }
  const rowStyle = {
    backgroundColor:'white',
    borderRadius:8,
    padding:8,
    paddingLeft:25,
    justifyContent:'start',
    alignItems:'center',
    marginTop:8,
    flexDirection:'row',
    display:'flex'
  }
  const rowItemStyle = {
    width:'18.5%',
    justifyContent:'flex-start',
    color:'#858585',
    fontWeight:'400',
    fontSize:'16px'
    // flexWap:'wrap',
    // display:'flex',
  }
  const subRowItemStyle = {
    // backgroundColor:'#EDEDED',
    borderRadius:25,
    marginRight:10,
    padding:4,
    paddingInline:5,
    marginBottom:4,
    fontWeight:'400',
    fontSize:'16px'
  }
  const statusAvailable = {
    backgroundColor:'#DDFFC2',
    paddingInline:20,
    borderRadius:107,
    paddingBlock:6,
    color:'#53BC00',
    height:'2em'
  }
  const statusUnavailable = {
    backgroundColor:'#FFE8E8',
    paddingInline:20,
    borderRadius:107,
    paddingBlock:6,
    color:'#BC0000',
    height:'2em'
  }
  const buttonStyle = {
    backgroundColor:'#012C63',
    fontSize:18,
    fontWeight:'500',
    borderRadius:8,
    paddingBlock:10,
    paddingInline:16.5,
    width:143,
    color:'white',
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
  }
  const styles = {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    p: 1,
    bgcolor: 'background.paper',
  };

  return (
    // <div >
      <div className='row support_row' 
      // style={{backgroundColor: '#E5E5E5'}} 
      >
        <div className='col-lg-2 col-md-2 support_col' >
    <Supportsidenav />
        </div>
        <div className='col-lg-10 col-md-10 support_col' >
    <div style={currentPageContainer} >

<div style={headerBarStyle} >
        Users
        <button
              onClick={()=>props.history.push({
                pathname: "/support/CreatNewUser",
              })
              }
              style={buttonStyle} >
                Add User
              </button>
</div>

<div style={{justifyContent:'flex-start',flexDirection:'row',display:'flex'}} >
  <div style={{fontWeight: '500', backgroundColor:'#012c63',  color: '#ffffff', width:'264px',padding:15,marginTop:10,borderRadius:10,alignItems:'baseline',marginRight:10}} >
  <span style={{fontSize: '34px'}}>{userStatus.total_user_count}</span> <br/>
    <span style={{fontSize: '20px', color: '#ffffff'}}>Total Users</span>
  </div>
  <div style={{fontWeight: '500', backgroundColor:'white',width:'264px',padding:15,marginTop:10,borderRadius:10,alignItems:'baseline',marginRight:10}} >
  <span style={{fontSize: '34px'}}>{userStatus.active_user_count}</span> <br/>
    <span style={{fontSize: '20px'}}>Total Active</span>
  </div>
  <div style={{fontWeight: '500', backgroundColor:'white',width:'264px',padding:15,marginTop:10,borderRadius:10,alignItems:'baseline',marginRight:10}} >
    {/* <h1>30</h1> */}
    <span style={{fontSize: '34px'}}>{userStatus.in_active_user_count}</span> <br/>
    <span style={{fontSize: '20px'}}>Total In-active</span>

  </div>

</div>
  <div style={{justifyContent:'start',display:'flex',flexDirection:'row',flex:1,padding:15}} >
          <TextField
          style={{backgroundColor:'white'}}
            id="outlined-with-icon"
            label="Search"
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(e)=>filterList(e.target.value)}
        variant="outlined"
      />
        {/* <OutlinedInput
            id="outlined-adornment-weight"
            // value={values.weight}
            // onChange={handleChange('weight')}
            endAdornment={
            <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            // inputProps={{
            //   'aria-label': 'search',
            // }}
          /> */}
          </div>

<div style={headerRowStyle} >
<h4 style={headerItemStyle} >ID</h4>
<h4 style={headerItemStyle} >Name</h4>
<h4 style={headerItemStyle} >Contact</h4>
<h4 style={headerItemStyle} >Role</h4>
<h4 style={headerItemStyle} >Center</h4>
<h4 style={{...headerItemStyle,justifyContent:'flex-end'}} >Status</h4>
</div>
{!isLoading?
search.length>0?
filteredList.map((item,index)=>
{
return(
  <div key={index} style={rowStyle} 
  // onClick={()=>props.history.push({
  //   pathname: "/support/Permissions",
  //   state: {
  //     user: item
  //   }
  // })
  // }
   >
    <h5 style={rowItemStyle} >{item.user_id}</h5>
    <h5 style={rowItemStyle} >{item.first_name}</h5>
    <h5 style={rowItemStyle} >{item.mobile_number}</h5>
    <h5 style={rowItemStyle} >
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
      {item.roles.map((itm,i)=>{
      return(
        <span key={i} style={subRowItemStyle} >
          {itm.role_name}
        </span>
      )
    })}
    </div>
    </h5>
    <h5 style={rowItemStyle} >
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
      {item.schools.map((itm,i)=>{
      return(
        <span key={i} style={subRowItemStyle} >
          {itm.school_name}
        </span>
      )
    })}
    </div>
    </h5>
    <h5 style={{...rowItemStyle,display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
      <span style={item.status?statusAvailable:statusUnavailable} >
      {item.status?`Active`:`In-active`}
      </span>
      <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={currentNumber === index ? 'long-menu' : undefined}
        aria-expanded={currentNumber === index ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleOpenMenu(e, index)
          // console.log(item,'itemmmmm')
          // setSelectedItem(item) 
        }
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={currentNumber === index}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
       
        <MenuItem 
        onClick={()=>{
          // console.log(item.first_name,index,'item')
          props.history.push({
          pathname: "/support/Permissions",
          state: {
            user: item
          }
        })
        }}
      >
            Edit User
          </MenuItem>
        <MenuItem 
        onClick={()=>{
          changeStatus(item.user_id,item.status)
          setAnchorEl(null);
        }}
        >
            Change Status
          </MenuItem>
      </Menu>
      </div>
      </h5>

  </div>
)
}):
userList.map((item,index)=>
{
return(
  <div key={index} style={rowStyle} 
  // onClick={()=>props.history.push({
  //   pathname: "/support/Permissions",
  //   state: {
  //     user: item
  //   }
  // })
  // }
   >
    <h5 style={rowItemStyle} >{item.user_id}</h5>
    <h5 style={rowItemStyle} >{item.first_name}</h5>
    <h5 style={rowItemStyle} >{item.mobile_number}</h5>
    <h5 style={rowItemStyle} >
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
      {item.roles.map((itm,i)=>{
      return(
        <span key={i} style={subRowItemStyle} >
          {itm.role_name}
        </span>
      )
    })}
    </div>
    </h5>
    <h5 style={rowItemStyle} >
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
      {item.schools.map((itm,i)=>{
      return(
        <span key={i} style={subRowItemStyle} >
          {itm.school_name}
        </span>
      )
    })}
    </div>
    </h5>
    <h5 style={{...rowItemStyle,display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
      <span style={item.status?statusAvailable:statusUnavailable} >
      {item.status?`Active`:`In-active`}
      </span>
      <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={currentNumber === index ? 'long-menu' : undefined}
        aria-expanded={currentNumber === index ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleOpenMenu(e, index)
          // console.log(item,'itemmmmm')
          // setSelectedItem(item) 
        }
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={currentNumber === index}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
       
        <MenuItem 
        onClick={()=>{
          // console.log(item.first_name,index,'item')
          props.history.push({
          pathname: "/support/Permissions",
          state: {
            user: item
          }
        })
        }}
      >
            Edit User
          </MenuItem>
        <MenuItem 
        onClick={()=>{
          changeStatus(item.user_id,item.status)
          setAnchorEl(null);
        }}
        >
            Change Status
          </MenuItem>
      </Menu>
      </div>
      </h5>

  </div>
)
}):
<div>
...Loading
</div>
}

</div>
      </div>
        {/* <SideDrawer/> */}
      </div>
// </div>
  )
}

export default Users